import React from 'react';
import PledgeType from '../components/PledgeType.js';
import CharitySelector from '../components/CharitySelector.js';
import GiftForm from '../components/GiftForm.js';
import DonationSummaryEditable from '../components/DonationSummaryEditable.js';
import DonationSummary from '../components/DonationSummary.js';
import DonatorForm from '../components/DonatorForm.js';
import Confirmation from '../components/Confirmation.js';

class PledgeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'pledgeTypeSelect',
      errorMessage: null,
      newDonation: {},
      buttonDisabled: false,
    };
    this.handlePledgeSelected = this.handlePledgeSelected.bind(this);
    this.handleCharitySelected = this.handleCharitySelected.bind(this);
    this.donationCreated = this.donationCreated.bind(this);
    this.handlePostPledge = this.handlePostPledge.bind(this);
    this.handleUpdateDonation = this.handleUpdateDonation.bind(this);
    this.handleUpdateCharity = this.handleUpdateCharity.bind(this);
    this.handleUpdateDonator = this.handleUpdateDonator.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.emailIsValid = this.emailIsValid.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.handleNewDonation = this.handleNewDonation.bind(this);
    this.handlePostPledge = this.handlePostPledge.bind(this);
    this.handleButtonDisable = this.handleButtonDisable.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedCharity) {
      this.setState({ view: 'giftForm' });
    }
  }

  handlePledgeSelected() {
    this.setState({ view: 'charitySelect' });
  }

  handleCharitySelected(charity) {
    this.props.charitySelected(charity);
    this.setState({ view: 'giftForm' });
  }

  handleNewDonation(newDonation) {
    this.setState({ newDonation: newDonation });
  }

  handleUpdateDonation(newDonation) {
    this.props.updateDonation(newDonation);
  }

  handleUpdateCharity(charity) {
    this.props.updateCharity(charity);
  }

  donationCreated() {
    if (Object.keys(this.state.newDonation).length !== 0) {
      this.setErrorMessage(null);

      this.props.updateCharity(this.props.selectedCharity);

      this.setState({ view: 'donatorForm' });
    } else {
      this.setErrorMessage(
        'Please add at least one gift to your donation before continuing.'
      );
    }
  }

  handleUpdateDonator(donator) {
    this.props.updateDonator(donator);
    this.setState({ buttonDisabled: false });
    this.setState({ errorMessage: null });
  }

  handlePostPledge() {
    if (!this.emailIsValid(this.props.donator.email)) {
      this.setErrorMessage(
        'Please enter a valid email address. We need this to send you a label for your donation'
      );
      return;
    }

    if (
      !this.props.donator.name ||
      !this.props.donator.email ||
      !this.props.donator.phone ||
      !this.props.donator.address
    ) {
      this.setErrorMessage(
        'Please complete all contact details before submitting.'
      );
      return;
    }

    this.handleUpdateDonation(this.state.newDonation);
    this.props.postPledge(this.state.newDonation, this.showConfirmation);
  }

  handleButtonDisable() {
    this.setState({ buttonDisabled: true });
  }

  showConfirmation() {
    this.setState({ view: 'confirmation' });
  }

  emailIsValid(emailAddress) {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailAddress || regex.test(emailAddress) === false) {
      return false;
    }
    return true;
  }

  setErrorMessage(message) {
    this.setState({ errorMessage: message });
  }

  componentWillUnmount() {
    const donator = {
      name: '',
      address: '',
      phone: '',
      email: '',
    };
    this.props.updateDonator(donator);
    this.props.resetValues();
  }

  render() {
    if (this.state.view === 'pledgeTypeSelect') {
      return (
        <div className='pledge-container'>
          <PledgeType pledgeSelected={this.handlePledgeSelected} />
        </div>
      );
    }

    if (this.state.view === 'charitySelect') {
      return (
        <div className='pledge-container'>
          <CharitySelector
            charities={this.props.charities}
            charitySelected={this.handleCharitySelected}
            findCharityById={this.props.findCharityById}
          />
        </div>
      );
    }

    if (this.state.view === 'giftForm') {
      return (
        <div className='pledge-container'>
          <GiftForm
            charity={this.props.selectedCharity}
            newDonation={this.state.newDonation}
            updateNewDonation={this.handleNewDonation}
          />
          <DonationSummaryEditable
            charity={this.props.selectedCharity}
            newDonation={this.state.newDonation}
            donationCreated={this.donationCreated}
            updateNewDonation={this.handleNewDonation}
            updateCharity={this.handleUpdateCharity}
            updateView={this.handlePledgeSelected}
            errorMessage={this.state.errorMessage}
          />
        </div>
      );
    }

    if (this.state.view === 'donatorForm') {
      return (
        <div className='pledge-container'>
          <DonatorForm
            donator={this.props.donator}
            updateDonator={this.handleUpdateDonator}
          />
          <DonationSummary
            charity={this.props.selectedCharity}
            newDonation={this.state.newDonation}
            donation={this.props.donation}
            handlePostPledge={this.handlePostPledge}
            errorMessage={this.state.errorMessage}
            buttonDisabled={this.state.buttonDisabled}
            handleButtonDisable={this.handleButtonDisable}
          />
        </div>
      );
    }

    if (this.state.view === 'confirmation') {
      return (
        <div className='pledge-container'>
          <Confirmation
            donator={this.props.donator}
            charity={this.props.selectedCharity}
            donation={this.props.donation}
            newDonation={this.state.newDonation}
            confirmationCode={this.props.confirmationCode}
            updatePledgeFlag={this.props.updatePledgeFlag}
          />
        </div>
      );
    }
  }
}

export default PledgeContainer;
