
  export const giftTypeImages = {
    "Boy 0-6 months": "male-0-6-m",
    "Boy 6-12 months": "male-6-12-m",
    "Boy 1-2 years": "male-1-2",
    "Boy 3-5 years": "male-3-5",
    "Boy 6-8 years": "male-6-8",
    "Boy 9-12 years": "male-9-12",
    "Boy 13-16 years": "male-13-16",
    "Boy 16+ years": "male-16",
    "Girl 0-6 months": "female-0-6-m",
    "Girl 6-12 months": "female-6-12-m",
    "Girl 1-2 years": "female-1-2",
    "Girl 3-5 years": "female-3-5",
    "Girl 6-8 years": "female-6-8",
    "Girl 9-12 years": "female-9-12",
    "Girl 13-16 years": "female-13-16",
    "Girl 16+ years": "female-16",
    "Gender Neutral 0-6 months": "gn-0-6-m",
    "Gender Neutral 6-12 months": "gn-6-12-m",
    "Gender Neutral 1-2 years": "gn-1-2",
    "Gender Neutral 3-5 years": "gn-3-5",
    "Gender Neutral 6-8 years": "gn-6-8",
    "Gender Neutral 9-12 years": "gn-9-12",
    "Gender Neutral 13-16 years": "gn-13-16",
    "Gender Neutral 16+ years": "gn-16"
  }
  
  export const giftTypes = [
    "Boy 0-6 months",
    "Boy 6-12 months",
    "Boy 1-2 years",
    "Boy 3-5 years",
    "Boy 6-8 years",
    "Boy 9-12 years",
    "Boy 13-16 years",
    "Boy 16+ years",
    "Girl 0-6 months",
    "Girl 6-12 months",
    "Girl 1-2 years",
    "Girl 3-5 years",
    "Girl 6-8 years",
    "Girl 9-12 years",
    "Girl 13-16 years",
    "Girl 16+ years",
    "Gender Neutral 0-6 months",
    "Gender Neutral 6-12 months",
    "Gender Neutral 1-2 years",
    "Gender Neutral 3-5 years",
    "Gender Neutral 6-8 years",
    "Gender Neutral 9-12 years",
    "Gender Neutral 13-16 years",
    "Gender Neutral 16+ years"
   ]
