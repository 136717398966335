import React from "react";
import BackdropModal from "../../Components/BackdropModal/BackdropModal";
import Button from "../../Components/Button/Button";
import Form from "../../Components/Form/Form";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { db, auth } from "../../Firebase/firebaseConfig.js";
import charityTemplate from "./CharityDataTemplate";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      charity: {
        organisation: "",
        addressLine1: "",
        addressLine2: "",
        location: "",
        postCode: "",
        contactName: "",
        contactEmail: "",
        confirmContactEmail: "",
        contactPhone: "",
        password: "",
        confirmPassword: "",
        participationStatus: {
          status: "Pending",
        },
        accountStatus: "Pending",
      },
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let propertyName = event.target.name;
    let charity = this.state.charity;
    charity[propertyName] = event.target.value;
    this.setState({ charity: charity });
  }

  async handleSubmit(event) {
    event.preventDefault();

    let charity = {
      ...this.state.charity,
    };

    if (
      !charity.organisation ||
      !charity.addressLine1 ||
      !charity.addressLine2 ||
      !charity.location ||
      !charity.postCode ||
      !charity.contactName ||
      !charity.contactEmail ||
      !charity.confirmContactEmail ||
      !charity.contactPhone ||
      !charity.password ||
      !charity.confirmPassword
    ) {
      this.setState({
        errorMessage: "Please complete all fields before submiting.",
      });
    } else if (!this.emailMatchesCheck()) {
      this.setState({
        errorMessage: "The emails need to match",
      });
    } else if (!this.emailIsValid(charity.contactEmail)) {
      this.setState({
        errorMessage: "Please enter a valid contact email address",
      });
    } else if (!this.passwordMatchesCheck()) {
      this.setState({
        errorMessage: "The passwords need to match",
      });
    } else {
      try {
        let charity = this.state.charity;

        // Create the user in Firebase
        const userCredential = await auth.createUserWithEmailAndPassword(
          charity.contactEmail,
          charity.password
        );

        delete charity.password;
        delete charity.confirmPassword;

        const user = userCredential.user;

        const charityDetails = {
          ...charityTemplate,
          ...charity,
          userId: user.uid,
          id: user.uid,
          canEditWishList: true,
        };

        await db
          .collection("charities")
          .doc(user.uid)
          .set(charityDetails)
          .then(function (docRef) {
            console.log("Document written with ID: ", docRef.id);
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });

        await db
          .collection("users")
          .doc(user.uid)
          .set({
            accessLevel: "partner",
            userId: user.uid,
          })
          .then(function (docRef) {
            console.log("Document written with ID: ", docRef.id);
          })
          .catch(function (error) {
            let message = `Error adding document: ${error}`;
            
            console.error(message);

            this.setState({
              errorMessage: message,
            });
          });
      } catch (error) {
        let message = `Error during registration: ${error.message}`;

        console.error(message);

        this.setState({
          errorMessage: message,
        });
      }
    }
  }

  emailIsValid(emailAddress) {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailAddress || regex.test(emailAddress) === false) {
      return false;
    }
    return true;
  }

  emailMatchesCheck() {
    let email = document.getElementById("contactEmail");
    let confirmEmail = document.getElementById("confirmContactEmail");

    if (!email || !confirmEmail) {
      return false;
    }

    if (email.value !== confirmEmail.value) {
      return false;
    }

    return true;
  }

  passwordMatchesCheck() {
    let password = document.getElementById("password");
    let confirmPassword = document.getElementById("confirmPassword");

    if (!password || !confirmPassword) {
      return false;
    }

    if (password.value !== confirmPassword.value) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <BackdropModal>
        <h1>Partner Registration</h1>
        <p className="backdrop-modal-sub-header">
          <strong>Only charities and organisations can apply.</strong>
          <br />
          Already a partner? <Link to="/login">Login</Link> to access your
          account.
        </p>

        <Form onSubmit={this.handleSubmit}>
          <label htmlFor="organisation">Organisation Name</label>
          <input
            type="text"
            name="organisation"
            id="organisation"
            value={this.state.charity.organisation}
            onChange={this.handleChange}
            placeholder="Barnardo's - East Kilbride"
            required
          />

          <label htmlFor="addressLine1">Address Line 1</label>
          <input
            type="text"
            name="addressLine1"
            id="addressLine1"
            value={this.state.charity.addressLine1}
            onChange={this.handleChange}
            placeholder="123 High Street"
            required
          />

          <label htmlFor="addressLine2">Address Line 2</label>
          <input
            type="text"
            name="addressLine2"
            id="addressLine2"
            value={this.state.charity.addressLine2}
            onChange={this.handleChange}
            placeholder="Glasgow"
            required
          />

          <label htmlFor="location">Location/Area</label>
          <input
            type="text"
            name="location"
            id="location"
            value={this.state.charity.location}
            onChange={this.handleChange}
            placeholder="East Kilbride"
            required
          />

          <label htmlFor="postCode">Postcode</label>
          <input
            type="text"
            name="postCode"
            id="postCode"
            value={this.state.charity.postCode}
            onChange={this.handleChange}
            placeholder="G31 2HL"
            required
          />

          <label htmlFor="contactName">Contact Name</label>
          <input
            type="text"
            name="contactName"
            id="contactName"
            value={this.state.charity.contactName}
            onChange={this.handleChange}
            placeholder="Jane Doe"
            required
          />

          <label htmlFor="contactPhone">Phone</label>
          <input
            type="text"
            name="contactPhone"
            id="contactPhone"
            value={this.state.charity.contactPhone}
            onChange={this.handleChange}
            placeholder="07123456789"
            required
          />

          <label htmlFor="contactEmail">Email</label>
          <input
            type="email"
            name="contactEmail"
            id="contactEmail"
            value={this.state.charity.contactEmail}
            onChange={this.handleChange}
            placeholder="jane@gmail.com"
            required
          />

          <label htmlFor="contactEmail">Confirm Email</label>
          <input
            type="email"
            name="confirmContactEmail"
            id="confirmContactEmail"
            value={this.state.charity.confirmContactEmail}
            onChange={this.handleChange}
            placeholder="jane@gmail.com"
            required
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={this.state.charity.password}
            onChange={this.handleChange}
            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            required
          />

          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={this.state.charity.confirmPassword}
            onChange={this.handleChange}
            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            required
          />

          <p className="red-text">{this.state.errorMessage}</p>

          <Button type="submit">Register as GSOC Partner</Button>
        </Form>
      </BackdropModal>
    );
  }
}

export default RegisterForm;
