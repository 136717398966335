import React, { useEffect, useState } from "react";
import Card from "../../Components/Card/Card";
import Button from "../../Components/Button/Button";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import * as ROUTES from "../../constants/routes.js";
import { db } from "../../Firebase/firebaseConfig";

import './PartnerDashboard.scss';

const PartnerDashboard = ({ selectedCharity }) => {
  const [formData, setFormData] = useState('');

  useEffect(() => {
    let charityData;

    const fetchData = async () => {
      await db
        .collection("charities")
        .where("userId", "==", selectedCharity)
        .onSnapshot((res) => {
          const dbUser = res.docs[0];
          charityData = dbUser.data();

          setFormData({ charity: charityData });
        });
    };

    fetchData();
  }, [selectedCharity]);

  let gsocContact = (formData?.charity?.publicContactEmail) ? formData?.charity?.publicContactEmail : 'Not assigned yet.';

  return (
    <div className="partner-dashboard">
      <div className="public-contact"><strong>Gift Manager/GSOC Contact:</strong> {gsocContact}</div>

      <h1>Welcome to Glasgow Spirit Of Christmas!</h1>

      <div className="card-container">
        <Card>
          <h3 className="card-sub-header">Charity Details</h3>
          <img src="/assets/edit.svg" />
          <NavLink to={ROUTES.PARTNER_PROFILE}>
            <Button>Manage</Button>
          </NavLink>
        </Card>
        <Card>
          <h3 className="card-sub-header">Wish List</h3>
          <img src="/assets/gift.svg" />
          <NavLink to={ROUTES.PARTNER_WISHLIST}>
            <Button>Manage</Button>
          </NavLink>
        </Card>
        <Card>
          <h3 className="card-sub-header">Gift Code</h3>
          <img src="/assets/magnifying-glass.svg" />
          <NavLink to={ROUTES.PARTNER_SEARCH_CODE}>
            <Button>Search</Button>
          </NavLink>
        </Card>
      </div>
    </div>
  );
};

export default PartnerDashboard;
