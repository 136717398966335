import React, { Children } from 'react';

import './Card.scss';

class Card extends React.Component {
  render() {
    return (
      <div className='card-container'>
        <div className='card'>{this.props.children}</div>
      </div>
    );
  }
}

export default Card;
