import React from "react";
import BackdropModal from "../../Components/BackdropModal/BackdropModal";
import Button from "../../Components/Button/Button";
import Form from "../../Components/Form/Form";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import * as ROUTES from "../../constants/routes.js";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: "",
        "remember-me": false,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let propertyName = event.target.name;
    let user = this.state.user;

    if (event.target.type === "checkbox") {
      user[propertyName] = event.target.checked;
    } else {
      user[propertyName] = event.target.value;
    }

    this.setState({ user: user });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.signIn(this.state.user);
  }

  render() {
    return (
      <BackdropModal>
        <h1>
          Welcome to our
          <br />
          Partner Portal
        </h1>
        <p className="backdrop-modal-sub-header">
          <strong>
            Access to registered charities and organisations only.
          </strong>
          <br />
          Individuals looking to be involved,{" "}
          {/* <a href="https://www.cultivatingmindfulness.org.uk/volunteer-with-us" target="_blank" aria-label="See our volunteers roles at cultivatingmindfulness.org.uk">click here!</a> */}
          <Link to="/pledge">click here!</Link>
        </p>

        {this.props.error && (
          <p className="red-text">Error: {this.props.error}</p>
        )}

        <Form onSubmit={this.handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={this.state.user.email}
            onChange={this.handleChange}
            placeholder="me@email.com"
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={this.state.user.password}
            onChange={this.handleChange}
            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          />

          <div className="base-form-remember-me">
            <div>
              <input
                type="checkbox"
                id="remember-me"
                name="remember-me"
                value={this.state.user.rememberMe}
                onChange={this.handleChange}
              />
              <label htmlFor="remember-me">Remember me</label>
            </div>

            <Link to={ROUTES.RESET}>Forgot Password?</Link>
          </div>

          <Button type="submit">Log in</Button>

          <p className="form-register">
            Not a partner yet? <Link to="/register">Register here</Link>
          </p>

          <p className="form-privacy">
            <a href="https://www.cultivatingmindfulness.org.uk/privacy-policy" target="_blank">Data Privacy & Terms of use</a>
            {/* <Link to="/privacy">Data Privacy & Terms of use</Link> */}
          </p>
        </Form>
      </BackdropModal>
    );
  }
}

export default LoginForm;
