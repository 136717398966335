import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const Firebase = firebase.initializeApp(config);

const db = Firebase.firestore();
const fv = firebase.firestore.FieldValue;

const auth = Firebase.auth();

export default Firebase;

export { db, fv };

export { auth };

if (window.location.hostname === "localhost") {
  console.log("localhost detected!");
  db.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
}
