export const LANDING = '/';
export const PLEDGE = '/pledge';
export const CHARITIES = '/charities';
export const ABOUTUS = '/aboutus';
export const LOGIN = '/login';
export const RESET = '/resetpassword';
export const ADMIN = '/admin';
export const QRSCAN = '/admin/qrcodescan/:id';
export const FAQ = '/faq';
export const DASHBOARD = '/dashboard';
export const LOGOUT = '/logout';
export const REGISTER = '/register';

export const PARTNER = '/partner';
export const PARTNER_PROFILE = '/partner/profile';
export const PARTNER_WISHLIST = '/partner/wishlist';
export const PARTNER_SEARCH_CODE = '/partner/searchcode/:id';