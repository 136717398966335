import React from 'react';
import { useAuth } from '../../shared/useAuth';
import {
  Link,
  NavLink,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../../Components/Button/Button';
import * as ROUTES from '../../constants/routes.js';

const DashboardButton = () => {
  const { user } = useAuth();

  let location = useLocation();
  location = location.pathname;

  const dashboards = ['/admin', '/partner'];

  if (!user) return null;

  // If the route matches any of the dashboard urls, don't show the bar
  if (dashboards.some((value) => location.includes(value))) {
    return null;
  }

  let dashboard;

  if (user.role == 'scanner') {
    dashboard = ROUTES.ADMIN;
  } else {
    dashboard = user.role;
  }

  return (
    <NavLink to={dashboard}>
      <Button>Dashboard</Button>
    </NavLink>
  );
};

export default DashboardButton;
