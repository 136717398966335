import React from 'react';
import { Link } from 'react-router-dom';

const FAQSteps = (props) => {
    return (
        <div>
            <p className='faq-text'><strong>Step 1</strong> – Choose to either make a cash donation or a gift donation.</p>
            <p className='faq-text'><strong>Step 2</strong> – To make a cash donation click <strong>"Pledge a Donation"</strong> above (or click <Link className="inline-link" to="/pledge">here</Link> and then click <strong>"I'd like to make a cash donation"</strong></p>
            <p className='faq-text'><strong>Step 3</strong> – To donate a Gift Made with Love to one of our charity partners click <strong>"Pledge a Donation"</strong> above (or click <Link className="inline-link" to="/pledge">here</Link>) and then click <strong>"I'd like to donate a gift"</strong>.</p>
            <p className='faq-text'><strong>Step 4</strong> – Choose from the list of <Link className="inline-link" to="/charities">Participating Charities &amp; Organisations</Link>.</p>
            <p className='faq-text'><strong>Step 5</strong> – Choose the number of children in each age group you would like to be a <strong>Secret Santa</strong> for.</p>
            <p className='faq-text'><strong>Step 6</strong> – Confirm your donation and receive an email confirmation with the details of when and where to deliver your gift(s) including a reference number or QR code to attach to your gift(s) when you drop them off at your chosen charity or children’s organisation.</p>
            <p className='faq-text'><strong>Step 7</strong> – Please ensure that you drop your gifts to the location confirmed within the specified delivery window to ensure your allocated child receives your gift on Christmas morning.</p>
        </div>
    );
}

export default FAQSteps;
