import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes.js'

const PageNotFound = function(){
  return (
    <React.Fragment>
      <h1>404 Page Not Found</h1>
      <p>Oops!  Looks like that page doesn't exist.</p>
      <Link to={ROUTES.LANDING} className="home-button">Home</Link>
    </React.Fragment>
  )
}

export default PageNotFound;
