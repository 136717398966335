import React from 'react';

import './Footer.scss'

const Footer = function () {
  return (
    <footer className="footer-banner">
      <div className="footer-container">
        <div className="footer-section footer-section-left">
          <a href="https://www.cultivatingmindfulness.org.uk/gsoc" aria-label="Visit Cultivating Mindfulness Website">
            <img src="/images/Cultivating-Mindfulness-Logo-Transparent.png" alt="Cultivating Mindfulness Logo" width="190px" height="90px" />
          </a>
        </div>

        <div className="footer-section">
          <p className="footer-text">{new Date().getFullYear()} by Cultivating Mindfulness</p>
          <br />
          <p className="footer-text">Registered Charity #SC048634</p>
        </div>

        <div className="footer-section footer-section-right">
          <a href="https://www.facebook.com/glasgowspiritofchristmas/" className="footer-social-link" aria-label="Visit our Facebook page" target="_blank">
            <img className="footer-social-logo" src="/images/social-facebook-logo.svg" aria-label="Visit our Facebook page" alt="Facebook Logo" width="36px" height="36px" />
          </a>

          <a href="https://www.instagram.com/glasgows_spirit_of_christmas/" className="footer-social-link" aria-label="Visit our Instagram page" target="_blank">
            <img className="footer-social-logo" src="/images/social-instagram-logo.svg" alt="Instagram Logo" width="36px" height="36px" />
          </a>

          <a href="https://twitter.com/glasgowspiritof" className="footer-social-link" aria-label="Visit our Twitter page" target="_blank">
            <img className="footer-social-logo" src="/images/social-twitter-logo.svg" alt="Twitter Logo" width="36px" height="36px" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
