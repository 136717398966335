import React, {Component} from 'react';
import CharityList from '../components/CharityList';

class CharityContainer extends Component {
    render(){
        return(
          <>
            <h1>Participating Charities</h1>
            <CharityList charities={this.props.charities} findCharityById={this.props.findCharityById} updateCharity={this.props.updateCharity} />
          </>
        )
    }
}

export default CharityContainer;
