const giftsRequired = {
  giftsRequired: {
    "Boy 0-6 months": 0,
    "Boy 1-2 years": 0,
    "Boy 13-16 years": 0,
    "Boy 16+ years": 0,
    "Boy 3-5 years": 0,
    "Boy 6-12 months": 0,
    "Boy 6-8 years": 0,
    "Boy 9-12 years": 0,
    "Gender Neutral 0-6 months": 0,
    "Gender Neutral 1-2 years": 0,
    "Gender Neutral 13-16 years": 0,
    "Gender Neutral 16+ years": 0,
    "Gender Neutral 3-5 years": 0,
    "Gender Neutral 6-12 months": 0,
    "Gender Neutral 6-8 years": 0,
    "Gender Neutral 9-12 years": 0,
    "Girl 0-6 months": 0,
    "Girl 1-2 years": 0,
    "Girl 13-16 years": 0,
    "Girl 16+ years": 0,
    "Girl 3-5 years": 0,
    "Girl 6-12 months": 0,
    "Girl 6-8 years": 0,
    "Girl 9-12 years": 0,
  },
};

const openingDetails = {
  openingDetails: {
    friday: "",
    monday: "",
    saturday: "",
    sunday: "",
    thursday: "",
    tuesday: "",
    wednesday: "",
  },
};

const participationStatus = {
    participationStatus : {
        lastModified: "",
        status: "",
    },
}

const charityTemplate = {
  addressLine1: "",
  addressLine2: "",
  contactEmail: "",
  contactName: "",
  contactPhone: "",
  dropoffWindow: "",
  id: "",
  location: "",
  organisation: "",
  postCode: "",
  publicContactEmail: "",
  canEditWishList: "",
  ...giftsRequired,
  ...openingDetails,
  ...participationStatus,
};

export default charityTemplate;