import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes.js';

class CharitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCharity: '',
      warningMessage: '',
    };
    this.handleCharitySelect = this.handleCharitySelect.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleCharitySelect(event) {
    this.setState({ selectedCharity: event.target.value, warningMessage: '' });
  }

  handleContinue() {
    if (!this.state.selectedCharity) {
      this.setState({ warningMessage: 'Please select a charity first.' });
    } else {
      const charity = this.props.findCharityById(this.state.selectedCharity);
      this.props.charitySelected(charity);
    }
  }

  quotaMet(charity) {
    var quotaMet = true;
    Object.keys(charity.giftsRequired).forEach((key) => {
      if (parseInt(charity.giftsRequired[key]) > 0) {
        quotaMet = false;
      }
    });
    return quotaMet;
  }

  render() {
    let filteredCharities = this.props.charities.filter((charity) => {
      return (
        !this.quotaMet(charity) &&
        charity.participationStatus.status !== 'Not Participating' &&
        charity.participationStatus.status !== 'Pending'
      );
    });

    const charityOptions = filteredCharities
      .sort((a, b) => {
        var textA = a.organisation.toUpperCase();
        var textB = b.organisation.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((charity) => {
        return (
          <option key={charity.id} value={charity.id}>
            {charity.organisation}
          </option>
        );
      });

    return (
      <React.Fragment>
        <h1>Pledge a Donation</h1>
        <div className='vertical-center-align'>
          <p className='charity-text'>
            Please select the charity you'd like to donate to and click
            "Continue".
          </p>
          <select
            defaultValue='select-charity'
            value={this.selectedCharity}
            onChange={this.handleCharitySelect}
          >
            <option disabled value='select-charity'>
              Select a charity
            </option>
            {charityOptions}
          </select>
          <button className='button-red-small' onClick={this.handleContinue}>
            Continue
          </button>
          <p className='charity-text'>{this.state.warningMessage}</p>
          <p className='charity-text'>
            Not sure which charity you'd like to donate to? Explore all
            participating charities{' '}
            <Link className='inline-link' to={ROUTES.CHARITIES}>
              here
            </Link>
            .
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default CharitySelector;
