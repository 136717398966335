import React from 'react';
import MainContainer from './MainContainer/containers/MainContainer.js';
// import 'normalize.css'
import './css/main.scss'

function App() {
  return (
    <div className="app">
      <MainContainer />
    </div>
  );
}

export default App;
