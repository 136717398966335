import React from 'react';
import Button from '../../Components/Button/Button';
import './SaveModal.scss';

export default function SaveModal({ children, isOpen, onConfirm, onCancel }) {
  if (!isOpen) return null;

  return (
    <div className='modal'>
      <div className='modal-content'>
        {children}

        <div className='button-container'>
          <Button onClick={onConfirm}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </div>
    </div>
  );
}
