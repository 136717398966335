import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./useAuth";

const GuestRoute = ({ component: Component, ...rest }) => {
    const { user, loading } = useAuth();

    if (loading) {
        // Display loading indicator
        return <div>Loading...</div>;
    }

    if (user && user.role) {
        let dashboard

        if (user.role === "scanner") {
            dashboard = "admin"
        } else {
            dashboard = user.role
        }

        // If user is logged redirect to user dashboard
        return <Redirect to={`/${dashboard}`} />;
    }

    // User is not logged in, render the component
    return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default GuestRoute;