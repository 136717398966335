import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase/firebase';
import Pledge from './Pledge';

const SearchCode = (props) => {
  const [pledges, setPledges] = useState([]);
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);

  const mapped = {};
  props.charities.forEach((charity) => {
    mapped[charity.id] = charity;
  });

  useEffect(() => {
    db.collection('pledges')
      .get()
      .then((result) => {
        console.log(result);
        const allPledges = [];
        result.forEach((doc) => {
          const record = doc.data();
          record.id = doc.id;
          allPledges.push(record);
        });
        setPledges(allPledges);
      })
      .catch((error) => {
        console.log('error loading pledges:', error);
      });
  }, [setPledges]);

  useEffect(() => {
    updateResults(search);
    // eslint-disable-next-line
  }, [pledges, search]);

  const updateResults = (text) => {
    if (text.length > 5) {
      const matching = pledges.filter((pledge) => {
        return pledge.id.toUpperCase().startsWith(text.toUpperCase());
      });
      setResults(matching);
    } else {
      setResults([]);
    }
  };

  const handleSearch = (e) => {
    const text = e.target.value;
    setSearch(text);
  };

  const updatePledges = (updatePledge) => {
    const newPledges = pledges.map((pledge) => {
      if (pledge.id === updatePledge.id) {
        return updatePledge;
      } else {
        return pledge;
      }
    });
    setPledges(newPledges);
    updateResults(search);
  };

  const handleReceived = async (pledge) => {
    try {
      await props.onReceived(pledge);
      const updatedPledge = {
        ...pledge,
        pledgeReceived: !pledge.pledgeReceived,
      };
      updatePledges(updatedPledge);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleAbandoned = (pledge) => {
    props.onAbandoned(pledge);
    const updatedPledge = {
      ...pledge,
      pledgeAbandoned: !pledge.pledgeAbandoned,
    };
    updatePledges(updatedPledge);
  };

  const handleComment = (updatedPledge) => {
    props.handlePostComment(updatedPledge);
    updatePledges(updatedPledge);
  };

  return (
    <>
      <h1>Search by Code</h1>
      <div>
        <label>Donation Code Starts With:</label>
        <input value={search} onChange={handleSearch} />
      </div>
      <ul>
        {results.map((pledge) => (
          <li key={pledge.id}>
            <Pledge
              pledge={pledge}
              onAbandoned={handleAbandoned}
              onReceived={handleReceived}
              handlePostComment={handleComment}
              charity={mapped[pledge.charityId]}
              isAdmin={props.isAdmin}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default SearchCode;
