import React from 'react';

class DonatorForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let propertyName = event.target.name;
    let updatedDonator = {
      ...this.props.donator,
      [propertyName]: event.target.value,
    };
    this.props.updateDonator(updatedDonator);
  }

  render() {
    return (
      <React.Fragment>
        <h1>Complete Pledge</h1>
        <div className='donator-form'>
          <h3>Your Details</h3>
          <form>
            <label htmlFor='donator-name'>Full Name or Company:</label>
            <input
              id='donator-name'
              name='name'
              type='text'
              value={this.props.donator.name}
              onChange={this.handleChange}
            />
            <label htmlFor='donator-address'>Address:</label>
            <input
              id='donator-address'
              name='address'
              type='text'
              value={this.props.donator.address}
              onChange={this.handleChange}
            />
            <label htmlFor='donator-phone'>Phone Number:</label>
            <input
              id='donator-phone'
              name='phone'
              type='text'
              value={this.props.donator.phone}
              onChange={this.handleChange}
            />
            <label htmlFor='donator-email'>Email Address:</label>
            <input
              id='donator-email'
              name='email'
              type='email'
              value={this.props.donator.email}
              onChange={this.handleChange}
            />
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default DonatorForm;
