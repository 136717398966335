import React from 'react';

function DonationSummary(props) {
  const handleContinue = () => {
    props.handlePostPledge();
    props.handleButtonDisable();
  };

  const pledgedGifts = Object.keys(props.newDonation).map((key, index) => {
    return (
      <li key={index}>
        <p>
          {props.newDonation[key]} x {key}
        </p>
      </li>
    );
  });

  return (
    <React.Fragment>
      <div className='donation-summary'>
        <h3 className='donation-summary-heading'>Your Donation Summary</h3>
        <h4 className='donation-summary-sub-heading'>Charity:</h4>
        <p>{props.charity.organisation}</p>
        <h4 className='donation-summary-sub-heading'>Gifts to be Donated:</h4>
        <ul className='gift-list'>{pledgedGifts}</ul>
      </div>
      <p className='error-message'>{props.errorMessage}</p>
      <button
        className='button-red-medium'
        onClick={handleContinue}
        disabled={props.buttonDisabled}
      >
        Submit Pledge
      </button>
    </React.Fragment>
  );
}

export default DonationSummary;
