import React, { Component } from 'react';
import ShareSocial from '../components/ShareSocial.js';


class ShareContainer extends Component {
  render() {

    const quote = "Donate a gift to brighten a child's Christmas morning!";
    const url = 'https://www.cultivatingmindfulness.org.uk/gsoc'

    return (
      <div className="share-container">
        <h1>Spread the spirit of Christmas!</h1>
        <p>Click an icon below to share on social media</p>
        <ShareSocial quote={quote} url={url}/>
      </div>
    )
  };
}

export default ShareContainer;
