import React from 'react';
import { CSVLink } from "react-csv";

const AdminCharityList = ({charities, pledges, handlePledgeView, handleEditView, handleFilterCharity}) => {

    function countPledgedGifts(charity) {
        var count = 0
        pledges.filter((e) => {
            return e.charityId === charity.id
        }).forEach((pledge) => {
            Object.keys(pledge.donation).forEach((key) => {
                count += parseInt(pledge.donation[key])
            })
        })
        return count
    }

    function countRequiredGifts(charity) {
        var count = 0
        Object.keys(charity.giftsRequired).forEach((key) => {
            count += parseInt(charity.giftsRequired[key])
        })
        return count
    }

    function hasNegativeGifts(charity) {
        var count = 0
        Object.keys(charity.giftsRequired).forEach((key) => {
            count += parseInt(charity.giftsRequired[key]) < 0 ? 1 : 0
        })
        return count > 0
    }

    function hasStrings(charity) {
        var count = 0
        Object.keys(charity.giftsRequired).forEach((key) => {
            count += (typeof charity.giftsRequired[key] === 'string' || charity.giftsRequired[key] instanceof String) ? 1 : 0
        })
        return count > 0
    }

    const mappedCharities = {}
    charities.forEach(charity => {
        mappedCharities[charity.id] = charity;
    })

    let affectedCharities = Object.keys(mappedCharities).map((e) => { 
        return mappedCharities[e] 
    })
    .sort((l, r) => { 
      var textA = l.organisation.toUpperCase();
      var textB = r.organisation.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    .map((e) => { 
        let pledgedGifts = countPledgedGifts(e)
        let required = countRequiredGifts(e)        
        let warning = hasNegativeGifts(e) ? "⚠️" : ""
        let stringsFound = hasStrings(e) ? "🎻" : ""

        function showPledges() {
            handlePledgeView(e)    
        }

        function editCharity() {
            handleEditView(e)
        }

        function filterCharity() {
            handleFilterCharity(e)
        }

        return (
            <tr key={e.id}>
                <td className="warnings">
                    {warning}{stringsFound}
                </td>
                <td className="charity">
                    <div>
                        <strong className="organisation">{e.organisation}</strong><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className="contactEmail">{e.contactEmail}</span>
                    </div>
                </td>
                <td>{pledgedGifts}</td>
                <td>{required}</td>
                <td>{pledgedGifts + required}</td>
                <td>
                    <button className="button-red-small" onClick={filterCharity}>Show on Dashboard</button>
                    <button className="button-grey-small" onClick={showPledges}>Manage Pledges</button>
                    <button className="button-grey-small" onClick={editCharity}>Edit Charity</button>
                </td>            
            </tr>
        )
    })

    const exportData = charities.map(charity => {
        return {
          organisation: charity.organisation,
          addressLine1: charity.addressLine1,
          addressLine2: charity.addressLine2,
          postcode: charity.postCode,
          location: charity.location,
          contactName: charity.contactName,
          contactEmail: charity.contactEmail,
          contactPhone: charity.contactPhone,
          publicContactEmail: charity.publicContactEmail,
          dropOffWindow: charity.dropoffWindow,
          monday: charity.openingDetails.monday,
          tuesday: charity.openingDetails.tuesday,
          wednesday: charity.openingDetails.wednesday,
          thursday: charity.openingDetails.thursday,
          friday: charity.openingDetails.friday,
          saturday: charity.openingDetails.saturday,
          sunday: charity.openingDetails.sunday,
        }
    })
    
    const exportHeaders = [
        {label: "Organisation", key: 'organisation'},
        {label: "Address line 1", key: 'addressLine1'},
        {label: "Address line 2", key: 'addressLine2'},
        {label: "Postcode", key: 'postcode'},
        {label: "Location", key: 'location'},
        {label: "Contact Name", key: 'contactName'},
        {label: "Contact Email", key: 'contactEmail'},
        {label: "Contact Phone", key: 'contactPhone'},
        {label: "Public Contact Email", key: 'publicContactEmail'},
        {label: "Drop Off Window", key: 'dropOffWindow'},
        {label: "Monday", key: 'monday'},
        {label: "Tuesday", key: 'tuesday'},
        {label: "Wednesday", key: 'wednesday'},
        {label: "Thursday", key: 'thursday'},
        {label: "Friday", key: 'friday'},
        {label: "Saturday", key: 'saturday'},
        {label: "Sunday", key: 'sunday'},
    ]

    return (
        <div>

            <h4>Charity List</h4>

            <div className="dashboard-container">
              <table className="dashboard-table">
                  <thead>
                    <tr>
                        <th className="warnings"></th>
                        <th>Charity</th>
                        <th>Total Pledged Gifts</th>
                        <th>Remaining Gifts</th>
                        <th>Total Gifts</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {affectedCharities}
                </tbody>

            </table>
            </div>
            <div className="dashboard-download-container">
                <CSVLink 
                    data={exportData} 
                    headers={exportHeaders}
                    filename={"charity-list.csv"}
                    className="download-btn"
                >
                    Download Charity Contact Information
                </CSVLink>
            </div>
        </div>
    )

}

export default AdminCharityList;