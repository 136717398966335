import React from "react";
import "./PartnerNav.scss";
import { NavLink, useRouteMatch } from "react-router-dom";

const PartnerNav = (props) => {
  let { url } = useRouteMatch();

  return (
    <nav className="partner-nav-bar">
      <ul>
        <li>
          <NavLink exact to={`${url}`}>Dashboard</NavLink>
        </li>
        <li>
          <NavLink to={`${url}/profile`}>Profile</NavLink>
        </li>
        <li>
          <NavLink to={`${url}/wishlist`}>Wish List</NavLink>
        </li>
        <li>
          <NavLink to={`${url}/searchcode`}>Search Code</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default PartnerNav;
