import React from 'react';
import emailjs from 'emailjs-com';
import GiftLabel from './GiftLabel';

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    window.location.reload();
  }

  render() {
    var emailSent = false;
    let props = this.props;

    if (!props.confirmationCode) {
      return <p>Loading....</p>;
    }

    let donationQty = Object.values(props.newDonation).reduce((acc, val) => {
      return parseInt(acc) + parseInt(val);
    });

    const pledgedGiftsString = Object.keys(props.donation)
      .map((key, index) => {
        return `${props.newDonation[key]} x ${key}`;
      })
      .join('<br>');

    const shortcode = props.confirmationCode.toUpperCase().slice(0, 6);

    function sendEmail(e) {
      if (process.env.REACT_APP_ENV === 'local') {
        console.log('EMAILS DISABLED IN DEV');
        return;
      }

      if (emailSent) {
        return;
      }

      var templateParams = {
        recipient: props.donator.email,
        donatorName: props.donator.name,
        organisation: props.charity.organisation,
        code: shortcode,
        confirmationCode: props.confirmationCode,
        donationType: pledgedGiftsString,
        donationQty: donationQty,
        addressLine1: props.charity.addressLine1,
        addressLine2: props.charity.addressLine2,
        postcode: props.charity.postcode,
        contactName: props.charity.contactName,
        contactEmail: props.charity.contactEmail,
        contactPhone: props.charity.contactPhone,
        dropoffWindow: props.charity.dropoffWindow,
        monday: props.charity.openingDetails.monday,
        tuesday: props.charity.openingDetails.tuesday,
        wednesday: props.charity.openingDetails.wednesday,
        thursday: props.charity.openingDetails.thursday,
        friday: props.charity.openingDetails.friday,
        saturday: props.charity.openingDetails.saturday,
        sunday: props.charity.openingDetails.sunday,
        publicContactEmail: props.charity.publicContactEmail,
        QRCODE: e.toDataURL('image/png'),
      };

      emailjs
        .send(
          'service_4g539zb',
          'template_qgq58p4',
          templateParams,
          'user_ZZKVmsuCv5tMLu7UkQFJC'
        )
        .then(
          function (response) {
            console.log('SUCCESS!', response.status, response.text);
          },
          function (error) {
            console.log('FAILED...', error);
          }
        );

      emailSent = true;
    }

    return (
      <div className='confirmation-container'>
        <h1>Thank you!</h1>
        <h3>Your pledge has submitted successfully</h3>
        <p>
          Thank you for submitting your pledge.{' '}
          <u>We will email you a copy of these details.</u>
        </p>
        <hr />
        <div className='gift-instructions'>
          <GiftLabel
            code={props.confirmationCode}
            charity={props.charity}
            codeGenerated={sendEmail}
          />
          <div className='info noprint'>
            <button className='printBtn' onClick={() => window.print()}>
              Print Page
            </button>
            <h3>How to make your donation</h3>
            <p>Please print this page and attach the label to the gift.</p>
            <p>
              Once you have your gift/s ready to go please take it/them in to
              the charity at the listed address.
            </p>
            <p>
              Please ensure you check the charity's delivery window and opening
              hours before attempting to drop off your gift.
            </p>
            <p>
              If you are unable to print the label, please ensure you write the{' '}
              <strong>donation code</strong> on a label.
            </p>
          </div>
        </div>
        <div className='noprint'>
          <hr />
          <h4 className='donation-summary-sub-heading'>Drop Off Window:</h4>
          <p>{props.charity.dropoffWindow}</p>
          <h4 className='donation-summary-sub-heading'>
            Charity Opening Times:
          </h4>
          <p>Monday: {props.charity.openingDetails.monday}</p>
          <p>Tuesday: {props.charity.openingDetails.tuesday}</p>
          <p>Wednesday: {props.charity.openingDetails.wednesday}</p>
          <p>Thursday: {props.charity.openingDetails.thursday}</p>
          <p>Friday: {props.charity.openingDetails.friday}</p>
          <p>Saturday: {props.charity.openingDetails.saturday}</p>
          <p>Sunday: {props.charity.openingDetails.sunday}</p>
          <h4 className='donation-summary-sub-heading'>Confirmation Code:</h4>
          <p>{shortcode}</p>
          <h4 className='donation-summary-sub-heading'>Get in Touch:</h4>
          <p>
            If you have a question about your pledge, you can contact Glasgow
            Spirit of Christmas by email at {props.charity.publicContactEmail}.
          </p>
          <hr />
          <h3 className='donation-summary-sub-heading'>
            Thank you for being a secret santa and bringing the magic and joy of
            Christmas to a local child this year.
          </h3>
        </div>
      </div>
    );
  }
}

export default Confirmation;
