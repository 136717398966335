import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useAuth } from '../../shared/useAuth';
import { db } from '../../Firebase/firebaseConfig';

import PartnerNav from '../components/PartnerNav';
import PartnerDashboard from '../components/PartnerDashboard';
import PartnerProfile from '../components/PartnerProfile';
import PartnerWishList from '../components/PartnerWishList';
import PartnerSearchCode from '../components/PartnerSearchCode';
import emailjs from 'emailjs-com';

export default function PartnerContainer() {
  const [loading, setLoading] = useState(true);
  const [charities, setCharities] = useState([]);
  let { path } = useRouteMatch();
  let { user } = useAuth();

  useEffect(() => {
    db.collection('charities').onSnapshot((res) => {
      const charitiesRes = res.docs.map((doc) => {
        let charity = doc.data();
        charity.id = doc.id;
        return charity;
      });
      setCharities([...charitiesRes]);
      setLoading(false);
    });
  }, []);

  const findCharityById = (id) => {
    console.log(charities);

    return charities.find((charity) => {
      return charity.id === id;
    });
  };

  const sendEmail = (pledge) => {
    if (process.env.REACT_APP_ENV === 'local') {
      console.log('EMAILS DISABLED IN DEV');
      return;
    }

    const charity = findCharityById(pledge.charityId);

    var templateParams = {
      recipient: pledge.donator.email,
      donorName: pledge.donator.name,
      organisation: charity.organisation,
    };

    emailjs
      .send(
        'service_4g539zb',
        'template_ernrfp7',
        templateParams,
        'user_ZZKVmsuCv5tMLu7UkQFJC'
      )
      .then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text);
        },
        function (error) {
          console.log('FAILED...', error);
        }
      );
  };

  const handleReceived = (pledge) => {
    if (!pledge.pledgeReceived) {
      sendEmail(pledge);
    }
    db.collection('pledges').doc(pledge.id).update({
      pledgeReceived: !pledge.pledgeReceived,
    });
  };

  const handleAbandoned = (pledge) => {
    db.collection('pledges').doc(pledge.id).update({
      pledgeAbandoned: !pledge.pledgeAbandoned,
      flagged: !pledge.flagged,
    });
  };

  const handlePostComment = (pledge) => {
    db.collection('pledges').doc(pledge.id).update({
      comments: pledge.comments,
      flagged: true,
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <PartnerNav />

        <Switch>
          <Route
            exact
            path={`${path}`}
            render={() => <PartnerDashboard selectedCharity={user.uid} />}
          />

          <Route
            path={`${path}/profile`}
            render={() => <PartnerProfile selectedCharity={user.uid} />}
          />

          <Route
            path={`${path}/wishlist`}
            render={() => <PartnerWishList selectedCharity={user.uid} />}
          />

          <Route
            path={`${path}/searchcode`}
            render={() => (
              <PartnerSearchCode
                onReceived={handleReceived}
                onAbandoned={handleAbandoned}
                handlePostComment={handlePostComment}
                isAdmin={false}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}
