import React from 'react';

import { auth } from '../../Firebase/firebase';

class LogoutContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // uniqueId: null,
            error: null
        }

    }

    signOut(){
        auth.signOut();
        window.location = "/login";
    }

    render(){
        return(
            <div>
                <h2> Sure you want to log out ? </h2>
                <button className="button-red-small" onClick={this.signOut}>Log Out</button>
            </div>
        )
    }

}





export default LogoutContainer;
