import { serverTimestamp } from 'firebase/firestore';
import React, {Component} from 'react';
import {db} from '../../Firebase/firebaseConfig.js';
import { participatingOptions } from "./ManagePortfolio";
import Select from "../../shared/Select";

import "./styles/AddCharityForm.css";

class AddCharityForm extends Component{
    constructor (props) {
        super(props);
        this.state = {
            charity: {
                organisation: "",
                addressLine1: "",
                addressLine2: "",
                location: "",
                postCode: "",
                giftsRequired: {},
                openingDetails: {
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    friday: "",
                    saturday: "",
                    sunday: ""
                },
                dropoffWindow: "",
                contactName: "",
                contactEmail: "",
                contactPhone: "",
                publicContactEmail: "",
                participationStatus: {
                  status: "Pending"
                }
            },
            errorMessage: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeGift = this.handleChangeGift.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeOpening = this.handleChangeOpening.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
    }

    componentDidMount(){
      let charity = this.state.charity;
      this.props.giftTypes.forEach((type) => {
        charity.giftsRequired[type] = 0;
      })
      this.setState({charity: charity})
    }

    handleChange(event){
        let propertyName = event.target.name;
        let charity = this.state.charity;
        charity[propertyName] = event.target.value;
        this.setState({charity: charity})
    }

    handleChangeOpening(event){
        let propertyName = event.target.name;
        let charity = this.state.charity;
        charity.openingDetails[propertyName] = event.target.value;
        this.setState({charity: charity})
    }

    handleChangeGift(event){
        let propertyName = event.target.name;
        let charity = this.state.charity;
        charity.giftsRequired[propertyName] = event.target.value;
        this.setState({charity: charity})
    }

    handleChangeStatus(status){
      const updatedCharity = {...this.state.charity, participationStatus: { status }}
      this.setState({ charity: updatedCharity })
    }

    handleSubmit(event){
      event.preventDefault();
      let charity = {
        ...this.state.charity, 
        participationStatus: { 
          ...this.state.charity.participationStatus, 
          lastModified: serverTimestamp() 
      }};
      if (!this.emailIsValid(charity.contactEmail)) {
        this.setState({errorMessage: "Please enter a valid contact email address"})
      } else if (!this.emailIsValid(charity.publicContactEmail)) {
        this.setState({errorMessage: "Please enter a valid public contact email address"})
      } else if(!charity.organisation || !charity.addressLine1 || !charity.addressLine2 || !charity.location || !charity.postCode || !charity.dropoffWindow || !charity.contactName || !charity.contactEmail || !charity.contactPhone || !charity.publicContactEmail){
        this.setState({errorMessage: "Please complete all fields before attempting to add the charity."})
      }else{
        Object.keys(this.state.charity.giftsRequired).forEach((key) => {
            charity.giftsRequired[key] = parseInt(charity.giftsRequired[key])
        });

        db.collection("charities").add(charity)
        .then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });
        this.props.viewDashboard();
      }
    }

    emailIsValid(emailAddress){
      // eslint-disable-next-line no-useless-escape
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailAddress || regex.test(emailAddress) === false) {
        return false
      }
      return true
    }

    render(){
        const giftsRequiredFormFields = this.props.giftTypes.map((giftType) => {
          return (
            <div className="add-charity-gift-input" key={giftType}>
              <label htmlFor={giftType}>{giftType}</label>
              <input type="number" name={giftType} id={giftType} onChange={this.handleChangeGift} placeholder="0" />
            </div>
          )
        })

        return(
            <div>
                <form className="basic-form" onSubmit={this.handleSubmit}>
                    <h3>Charity Details</h3>
                    <label htmlFor="organisation">Charity Name</label>
                    <input type="text" name="organisation" id="organisation" value={this.state.charity.organisation} onChange={this.handleChange}placeholder="Barnardo's - East Kilbride"/>

                    <label htmlFor="addressLine1">Address Line 1</label>
                    <input type="text" name="addressLine1" id="addressLine1" value={this.state.charity.addressLine1} onChange={this.handleChange} placeholder="123 High Street"/>

                    <label htmlFor="addressLine2">Address Line 2</label>
                    <input type="text" name="addressLine2" id="addressLine2" value={this.state.charity.addressLine2} onChange={this.handleChange}placeholder="Glasgow"/>

                    <label htmlFor="location">Location (Displayed on Charity List)</label>
                    <input type="text" name="location" id="location" value={this.state.charity.location} onChange={this.handleChange}placeholder="East Kilbride"/>

                    <label htmlFor="postCode">Postcode</label>
                    <input type="text" name="postCode" id="postCode" value={this.state.charity.postCode} onChange={this.handleChange}placeholder="G31 2HL"/>

                    <label className={`add-charity-status-select participation-select ${this.state.charity.participationStatus.status}`}>
                      Charity Status
                      <Select options={participatingOptions} selectedOption={this.state.charity.participationStatus.status} setSelectedOption={this.handleChangeStatus} />
                    </label>
                    <h3>Contact Details</h3>
                    <label htmlFor="contactName">Contact Name</label>
                    <input type="text" name="contactName" id="contactName" value={this.state.charity.contactName} onChange={this.handleChange}placeholder="Jane Doe"/>

                    <label htmlFor="contactEmail">Contact Email</label>
                    <input type="text" name="contactEmail" id="contactEmail" value={this.state.charity.contactEmail} onChange={this.handleChange}placeholder="jane@gmail.com"/>

                    <label htmlFor="contactPhone">Contact Phone Number</label>
                    <input type="text" name="contactPhone" id="contactPhone" value={this.state.charity.contactPhone} onChange={this.handleChange}placeholder="07123456789"/>

                    <h3>Opening Hours</h3>
                    <label htmlFor="monday">Monday:</label>
                    <input type="text" name="monday" id="monday" value={this.state.charity.openingDetails.monday} onChange={this.handleChangeOpening} placeholder="9-5"/>

                    <label htmlFor="tuesday">Tuesday:</label>
                    <input type="text" name="tuesday" id="tuesday" value={this.state.charity.openingDetails.tuesday} onChange={this.handleChangeOpening} placeholder="9-5"/>

                    <label htmlFor="wednesday">Wednesday:</label>
                    <input type="text" name="wednesday" id="wednesday" value={this.state.charity.openingDetails.wednesday} onChange={this.handleChangeOpening} placeholder="closed"/>

                    <label htmlFor="thursday">Thursday:</label>
                    <input type="text" name="thursday" id="thursday" value={this.state.charity.openingDetails.thursday} onChange={this.handleChangeOpening} placeholder="10-6.30"/>

                    <label htmlFor="friday">Friday:</label>
                    <input type="text" name="friday" id="friday" value={this.state.charity.openingDetails.friday} onChange={this.handleChangeOpening} placeholder="10-5"/>

                    <label htmlFor="saturday">Saturday:</label>
                    <input type="text" name="saturday" id="saturday" value={this.state.charity.openingDetails.saturday} onChange={this.handleChangeOpening} placeholder="12-4"/>

                    <label htmlFor="sunday">Sunday:</label>
                    <input type="text" name="sunday" id="sunday" value={this.state.charity.openingDetails.sunday} onChange={this.handleChangeOpening} placeholder="closed"/>

                    <label htmlFor="dropoffWindow">Dropoff Window</label>
                    <input type="text" name="dropoffWindow" id="dropoffWindow" value={this.state.charity.dropoffWindow} onChange={this.handleChange} placeholder="25th November - 17th December"/>

                    <h3>Gifts Required</h3>
                    {giftsRequiredFormFields}

                    <h3>Public Facing Contact Details</h3>
                    <label htmlFor="publicContactEmail">Email Address:</label>
                    <input type="text" name="publicContactEmail" id="publicContactEmail" value={this.state.charity.publicContactEmail} onChange={this.handleChange} placeholder="studentemailaddress@gsoc.com"/>

                    <p>{this.state.errorMessage}</p>
                    <button className="button-red-small" type="submit">Add Charity</button>

                    <br />
                </form>
            </div>
        )
    }
}

export default AddCharityForm;
