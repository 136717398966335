import React from 'react';

const AdminNavBar = (props) => {
  function dashboardView(){
    props.viewDashboard();
  }

  function addCharityView(){
    props.viewAddCharity();
  }

  function editPortfolioView(){
    props.viewEditPortfolio();
  }

  function managePortfolioView(){
    props.viewManagePortfolio();
  }

  function searchDonorView() {
    props.viewSearchDonor();
  }

  function searchCodeView() {
    props.viewSearchCode();
  }

  function manageFlaggedView() {
    props.viewManageFlagged();
  }

  function charityList() {
    props.viewCharityList();
  }

  function signOut(){
    window.location = '/logout';
  }

  let adminButtons = null;
  if(props.user.accessLevel === "admin"){
    adminButtons = (
      <div className="admin-nav-bar-left">
        <button className="admin-nav-bar-link" onClick={dashboardView}>Dashboard</button>
        <button className="admin-nav-bar-link" onClick={charityList}>Charity List</button>
        <button className="admin-nav-bar-link" onClick={editPortfolioView}>Edit Portfolio</button>
        <button className="admin-nav-bar-link" onClick={managePortfolioView}>Manage Portfolio</button>
        <button className="admin-nav-bar-link" onClick={addCharityView}>Add a Charity</button>
        <button className="admin-nav-bar-link" onClick={searchDonorView}>Search Donor</button>
        <button className="admin-nav-bar-link" onClick={searchCodeView}>Search Code</button>
        <button className="admin-nav-bar-link" onClick={manageFlaggedView}>Manage Flagged</button>
      </div>
    )
  } else if (props.user.accessLevel === "scanner"){
    adminButtons = (
      <div className="admin-nav-bar-left">
        <button className="admin-nav-bar-link" onClick={searchCodeView}>Search Code</button>
      </div>    
    )
  }

  return (
    <div className="admin-nav-bar">
        {adminButtons}
    </div>
  )
}

export default AdminNavBar;
