import React from 'react';


const CharityInfo = ({charity, toggleInfo}) => {

    return(
        <>
        <hr />
        <div className="col-info">
          <p className="charity-info-text"><strong>Address</strong></p>
          <p className="charity-info-text">{charity.addressLine1}</p>
          <p className="charity-info-text">{charity.addressLine2}</p>
          <p className="charity-info-text">{charity.postCode}</p>
          <p className="charity-info-text"><strong>Opening Times</strong></p>
          <p className="charity-info-text">Monday: {charity.openingDetails.monday}</p>
          <p className="charity-info-text">Tuesday: {charity.openingDetails.tuesday}</p>
          <p className="charity-info-text">Wednesday: {charity.openingDetails.wednesday}</p>
          <p className="charity-info-text">Thursday: {charity.openingDetails.thursday}</p>
          <p className="charity-info-text">Friday: {charity.openingDetails.friday}</p>
          <p className="charity-info-text">Saturday: {charity.openingDetails.saturday}</p>
          <p className="charity-info-text">Sunday: {charity.openingDetails.sunday}</p>
          <p className="charity-info-text"><strong>Gift Drop Off Window</strong></p>
          <p className="charity-info-text">{charity.dropoffWindow}</p>
        </div>
        <button className="button-grey-small" onClick={toggleInfo}>Less Info</button>
        </>
    )
}

export default CharityInfo;
