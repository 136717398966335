import React, { useState } from 'react'

import "./Select.css"

//accessible and stylable select

const Select = ({ options, selectedOption, setSelectedOption }) => {
  const [selectOpen, setSelectOpen] = useState(false)

  const toggleOpen = () => {
    setSelectOpen(!selectOpen)
  }

  const selectOption = option => {
    setSelectOpen(false)
    setSelectedOption(option)
  }

  const handleOptionKeyDown = (event, option) => {
    switch (event.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        event.preventDefault()
        selectOption(option)
        break
      default:
        break
    }
  }

  const handleListKeydown = event => {
    if (event.key === "Tab") return;
    event.preventDefault()

    const activeIndex = options.findIndex(option => option === selectedOption)

    switch (event.key) {
      case 'ArrowUp':
        if (options.length > 1) {
          const newOption = options[activeIndex > 0 ? activeIndex - 1 : options.length - 1]
          setSelectedOption(newOption)
        }
        break
      case 'ArrowDown':
        if (options.length > 1) {
          const newOption = options[activeIndex < options.length - 1 ? activeIndex + 1 : 0]
          setSelectedOption(newOption)
        }
        break
      case ' ':
      case 'Space':
        setSelectOpen(true)
        break
      case 'Enter':
        toggleOpen()
        break
      case 'Escape':
        setSelectOpen(false)
        break
      default:
        break
    }
  }

  return (
    <div className="select-container" onBlur={() => setSelectOpen(false)}>
      <button
        type="button"
        onClick={toggleOpen}
        aria-haspopup="listbox"
        aria-expanded={selectOpen}
        onKeyDown={handleListKeydown}
        className={`select-trigger ${
          selectOpen ? "select-open" : ""
        }`}
      >
        {selectedOption && selectedOption !== "" ? selectedOption : "Please select an option"}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#323232"/>
        </svg>
      </button>
      <ul
        tabIndex={-1}
        role="listbox"
        aria-activedescendant={selectedOption}
        className={`select-list ${selectOpen ? "select-list-open" : ""}`}
        onKeyDown={handleListKeydown}
      >
        {options.map(option => (
          <li
            key={option}
            id={option}
            role="option"
            aria-selected={selectedOption === option}
            tabIndex={0}
            onMouseDown={() => selectOption(option)}
            onKeyDown={(event) => handleOptionKeyDown(event, option)}
            className="select-option"
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select