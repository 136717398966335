import React from 'react';
import { CSVLink } from "react-csv";

class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.handleCharitySelected = this.handleCharitySelected.bind(this);
    this.getCharitynameById = this.getCharitynameById.bind(this)
    this.getPledgeStatus = this.getPledgeStatus.bind(this)
    this.getPledgeDate = this.getPledgeDate.bind(this)
    this.getPledgeDetails = this.getPledgeDetails.bind(this)
    this.state = {  
      donorExportData: []
    }
  }

  componentDidMount(){
    this.props.charitySelected(this.props.charity);
  }

  handleCharitySelected(event){
    if(event.target.value === "all"){
      this.props.charitySelected(null);
    }else{
      let charity = this.props.findCharityById(event.target.value);
      this.props.charitySelected(charity);
    }
  }

  getCharitynameById(id){
    const foundCharity = this.props.findCharityById(id)
    if (foundCharity) {
      return foundCharity.organisation
    } else {
      console.log('No charity found', id)
    }
  }

  getPledgeStatus(pledge){
    if (pledge.pledgeAbandoned) {
      return 'Abandoned'
    } else if (pledge.pledgeReceived) {
      return 'Received'
    } else {
      return 'Not Received'
    }
  }

  getPledgeDate(pledge){
    return new Date(pledge.datePledged.seconds * 1000).toLocaleString("en-GB")
  }

  getPledgeDetails(donations){
    const detailsArr = []
    Object.keys(donations).forEach(key => {
      if (donations[key] > 0) {
        detailsArr.push(`${key}: ${donations[key]}`)
      }
    })
    return detailsArr.join(', ')
  }

  getDonorExportData(pledges){
    const mappedPledges = pledges.map(pledge => {
      return {
        charityName: this.getCharitynameById(pledge.charityId),
        pledgeId: pledge.id,
        datePledged: this.getPledgeDate(pledge),
        pledgeStatus: this.getPledgeStatus(pledge),
        donorName: pledge.donator.name,
        donorEmail: pledge.donator.email,
        donorAddress: pledge.donator.address,
        donorPhone: `=""${pledge.donator.phone}""`, //workaround to get excel to keep leading 0
        pledgeDetails: this.getPledgeDetails(pledge.donation)
      }
    })
    this.setState({donorExportData: mappedPledges})
  }

  render(){
    let giftTypeReceived = {};
    let giftTypeAbandoned = {};
    let giftTypeAwaitingReceipt = {};
    let giftTypeRemaining = {};

    this.props.giftTypes.forEach((type) => {
      giftTypeReceived[type] = 0;
      giftTypeAbandoned[type] = 0;
      giftTypeAwaitingReceipt[type] = 0;
      giftTypeRemaining[type] = 0;
    })

    let allPledges = [];

    if(!this.props.charity){
      allPledges = this.props.pledges;
    }else{
      allPledges = this.props.pledges.filter((pledge) => {
        return pledge.charityId === this.props.charity.id;
      })
    }

    const userPledges = allPledges.filter((pledge) => {
      return this.props.user.charities.includes(pledge.charityId)
    })

    let receivedPledges = userPledges.filter((pledge) => {
      return pledge.pledgeReceived === true;
    })

    let receivedGifts = [];
    let totalReceivedGifts = 0;
    receivedPledges.forEach((pledge) => {
      Object.keys(pledge.donation).forEach((key) => {
        let gift = {};
        gift[key] = pledge.donation[key];
        receivedGifts.push(gift);
        totalReceivedGifts += parseInt(pledge.donation[key]);
        giftTypeReceived[key] += parseInt(pledge.donation[key]);
      })
    })

    let abandonedPledges = userPledges.filter((pledge) => {
      return pledge.pledgeAbandoned === true;
    })

    let abandonedGifts = [];
    let totalAbandonedGifts = 0;
    abandonedPledges.forEach((pledge) => {
      Object.keys(pledge.donation).forEach((key) => {
        let gift = {};
        gift[key] = pledge.donation[key];
        abandonedGifts.push(gift);
        totalAbandonedGifts += parseInt(pledge.donation[key]);
        giftTypeAbandoned[key] += parseInt(pledge.donation[key]);
      })
    })

    let awaitingReceiptPledges = userPledges.filter((pledge) => {
      return (!pledge.pledgeReceived && !pledge.pledgeAbandoned)
    })

    let awaitingReceiptGifts = [];
    let totalAwaitingReceiptGifts = 0;
    awaitingReceiptPledges.forEach((pledge) => {
      Object.keys(pledge.donation).forEach((key) => {
        let gift = {};
        gift[key] = pledge.donation[key];
        awaitingReceiptGifts.push(gift);
        totalAwaitingReceiptGifts += parseInt(pledge.donation[key]);
        giftTypeAwaitingReceipt[key] += parseInt(pledge.donation[key]);
      })
    })

    let totalPledgedGifts = totalReceivedGifts + totalAwaitingReceiptGifts;

    let userCharityOptions = this.props.charities.filter((charity) => {
      return this.props.user.charities.includes(charity.id);
    })

    let totalRemainingGifts = 0;
    let giftTypeTotal = {}

    if(!this.props.charity){
      userCharityOptions.forEach((charity) => {
        Object.keys(charity.giftsRequired).forEach((key) => {
          totalRemainingGifts += parseInt(charity.giftsRequired[key]);
          giftTypeRemaining[key] += parseInt(charity.giftsRequired[key]);
        })        
      })

      userCharityOptions.forEach((charity) => {
        Object.keys(charity.giftsRequired).forEach((key) => {
          giftTypeTotal[key] = giftTypeRemaining[key] + giftTypeReceived[key] + giftTypeAwaitingReceipt[key] + giftTypeAbandoned[key]
        })
      })

    }else{
      Object.keys(this.props.charity.giftsRequired).forEach((key) => {
        totalRemainingGifts += parseInt(this.props.charity.giftsRequired[key]);
        giftTypeRemaining[key] += parseInt(this.props.charity.giftsRequired[key]);
      })

      Object.keys(this.props.charity.giftsRequired).forEach((key) => {
        giftTypeTotal[key] = giftTypeRemaining[key] + giftTypeReceived[key] + giftTypeAwaitingReceipt[key] + giftTypeAbandoned[key]
      })
    }

    let totalGifts = totalPledgedGifts + totalRemainingGifts

    let giftTypeTableEntries = this.props.giftTypes.map((type) => {
      return (
        <tr key={type}>
          <td>{type}</td>
          <td>{giftTypeReceived[type]}</td>
          <td>{giftTypeAwaitingReceipt[type]}</td>
          <td>{giftTypeAbandoned[type]}</td>
          <td>{giftTypeReceived[type] + giftTypeAwaitingReceipt[type] + giftTypeAbandoned[type]}</td>
          <td>{giftTypeRemaining[type]}</td>
          <td className="summary">{giftTypeTotal[type]}</td>
        </tr>
      )
    })

    const charityOptions = userCharityOptions.sort((a, b) => {
      var textA = a.organisation.toUpperCase();
      var textB = b.organisation.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }).map((charity) => {
      return <option key={charity.id} value={charity.id}>{charity.organisation}</option>
    })

    let selectedValue = this.props.charity != null ? this.props.charity.id : "all"

    const allGiftsData = {
      type: 'All Gifts',
      received: totalReceivedGifts,
      notReceived: totalAwaitingReceiptGifts,
      abandoned: totalAbandonedGifts,
      pledged: totalPledgedGifts,
      remaining: totalRemainingGifts,
      total: totalGifts
    }

    const rowData = this.props.giftTypes.map((type) => {
      return {
        type: type,
        received: giftTypeReceived[type],
        notReceived: giftTypeAwaitingReceipt[type],
        abandoned: giftTypeAbandoned[type],
        pledged: giftTypeReceived[type] + giftTypeAwaitingReceipt[type] + giftTypeAbandoned[type],
        remaining: giftTypeRemaining[type],
        total: giftTypeTotal[type]
      }
    })

    const exportData = [allGiftsData, ...rowData]

    const exportHeaders = [
      {label: "Gift Type", key: "type"},
      {label: "Received Gifts", key: "received"},
      {label: "Gifts Not Received", key: "notReceived"},
      {label: "Abandoned Gifts", key: "abandoned"},
      {label: "Total Pledged Gifts", key: "pledged"},
      {label: "Remaining Gifts", key: "remaining"},
      {label: "Total Pledged + Remaining Gifts", key: "total"},
    ]


    const donorExportHeaders = [
      {label: "Charity Name", key: "charityName"},
      {label: "Pledge ID", key: "pledgeId"},
      {label: "Date Pledged", key: "datePledged"},
      {label: "Pledge Status", key: "pledgeStatus"},
      {label: "name", key: "donorName"},
      {label: "email", key: "donorEmail"},
      {label: "address", key: "donorAddress"},
      {label: "phone", key: "donorPhone"},
      {label: "Pledge Details", key: "pledgeDetails"}
    ]

    return (
      <React.Fragment>
        <h1>Dashboard</h1>
        <div className="dashboard-charity-selector">
          <select className="dashboard-select" onChange={this.handleCharitySelected} value={selectedValue}>
            <option value="all">All Charities</option>
            {charityOptions}
          </select>

          <CSVLink 
            data={this.state.donorExportData} 
            headers={donorExportHeaders}
            asyncOnClick={true}
            onClick={(event, done) => {
              this.getDonorExportData(userPledges)
              console.log('DONOR EXPORT DATA', this.state.donorExportData)
              done()
            }}
            filename={"donor-export.csv"}
            className="download-btn"
          >
            Download Donors
          </CSVLink>

        </div>
        <div className="dashboard-container">
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Gift Type</th>
                <th>Received Gifts</th>
                <th>Gifts Not Received</th>
                <th>Abandoned Gifts</th>
                <th>Total Pledged Gifts</th>
                <th>Remaining Gifts</th>
                <th className="summary">Total Pledged +<br/>Remaining Gifts =</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="dashboard-table-top-row">All Gifts</td>
                <td className="dashboard-table-top-row">{totalReceivedGifts}</td>
                <td className="dashboard-table-top-row">{totalAwaitingReceiptGifts}</td>
                <td className="dashboard-table-top-row">{totalAbandonedGifts}</td>
                <td className="dashboard-table-top-row">{totalPledgedGifts}</td>
                <td className="dashboard-table-top-row">{totalRemainingGifts}</td>
                <td className="dashboard-table-top-row summary">{totalGifts}</td>
              </tr>
              {giftTypeTableEntries}
            </tbody>
          </table>
        </div>
        <div className="dashboard-download-container">
          <CSVLink 
            data={exportData} 
            headers={exportHeaders}
            filename={"dashboard-export.csv"}
            className="download-btn"
          >
            Download
          </CSVLink>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
