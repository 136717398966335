import React, { Component } from 'react';
import PledgeManager from '../components/PledgeManager';
import Dashboard from '../components/Dashboard.js';
import AddCharityForm from '../components/AddCharityForm.js';
import AdminNavBar from '../../NavigationContainer/components/AdminNavBar.js';
import AdminCharityList from '../components/AdminCharityList.js';
import EditPortfolio from '../components/EditPortfolio.js';
import ManagePortfolio from '../components/ManagePortfolio.js';
import SearchDonor from '../components/SearchDonor.js';
import SearchCode from '../components/SearchCode.js';
import ManageFlagged from '../components/ManageFlagged.js';
import { db } from '../../Firebase/firebase';
import { auth } from '../../Firebase/firebase.js';
import EditCharity from '../components/EditCharity';
import { giftTypes } from '../../constants/giftTypes.js';
import emailjs from 'emailjs-com';
import { serverTimestamp } from 'firebase/firestore';

class AdminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      pledges: [],
      charities: [],
      view: 'loading',
      selectedCharity: null,
      selectedCharityPledges: [],
      giftTypes: giftTypes,
    };
    this.viewPledgeManager = this.viewPledgeManager.bind(this);
    this.viewDashboard = this.viewDashboard.bind(this);
    this.viewCharityList = this.viewCharityList.bind(this);
    this.viewAddCharity = this.viewAddCharity.bind(this);
    this.viewEditPortfolio = this.viewEditPortfolio.bind(this);
    this.viewManagePortfolio = this.viewManagePortfolio.bind(this);
    this.viewEditCharity = this.viewEditCharity.bind(this);
    this.viewSearchDonor = this.viewSearchDonor.bind(this);
    this.viewSearchCode = this.viewSearchCode.bind(this);
    this.viewManageFlagged = this.viewManageFlagged.bind(this);
    this.handleEditCharityForm = this.handleEditCharityForm.bind(this);
    this.charitySelected = this.charitySelected.bind(this);
    this.findCharityById = this.findCharityById.bind(this);
    this.handleAbandoned = this.handleAbandoned.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleReceived = this.handleReceived.bind(this);
  }

  componentDidMount() {
    this.listener = auth.onAuthStateChanged((user) => {
      if (!user) {
        window.location = '/login';
      }

      if (user) {
        auth.currentUser.getIdTokenResult().then((idTokenResult) => {
          db.collection('users')
            .where('userId', '==', user.uid)
            .onSnapshot((res) => {
              const dbUser = res.docs[0];

              const userDetails = {
                email: user.email,
                userID: dbUser.id,
                authID: user.uid,
                accessLevel: dbUser.data().accessLevel,
              };

              // Ensures charities is not undefined
              if (dbUser.data().charities) {
                userDetails.charities = dbUser.data().charities;
              } else {
                userDetails.charities = [];
              }
              this.setState({ user: userDetails });

              if (userDetails.accessLevel === 'scanner') {
                this.viewSearchCode();
              } else {
                this.viewDashboard();
              }

              // For admin gets all pledges & charities
              if (this.state.user && this.state.user.accessLevel === 'admin') {
                db.collection('pledges').onSnapshot((res) => {
                  const pledges = res.docs.map((doc) => {
                    let pledge = doc.data();
                    pledge.id = doc.id;
                    return pledge;
                  });
                  this.setState({ pledges: pledges });
                });
              }

              // Load Charities
              if (
                this.state.user &&
                (this.state.user.accessLevel === 'admin' ||
                  this.state.user.accessLevel === 'scanner')
              ) {
                db.collection('charities').onSnapshot((res) => {
                  const charities = res.docs.map((doc) => {
                    let charity = doc.data();
                    charity.id = doc.id;
                    return charity;
                  });
                  this.setState({ charities: charities });
                });
              }

              // For normal user gets only charities and padges that are selected
              else {
                if (
                  this.state.user &&
                  this.state.user.charities &&
                  this.state.user.charities.length > 0
                ) {
                  const charitiesArray = [];
                  for (let charity of this.state.user.charities) {
                    db.collection('charities')
                      .doc(charity)
                      .onSnapshot((res) => {
                        const selectedCharity = res.data();
                        selectedCharity.id = res.id;
                        charitiesArray.push(selectedCharity);
                      });
                  }
                  this.setState({ charities: charitiesArray });
                }
                // Wouldn't work with using this.state.charities
                if (this.state.user.charities.length > 0) {
                  const pledgesArray = [];
                  for (let charity of this.state.user.charities) {
                    db.collection('pledges')
                      .where('charityId', '==', charity)
                      .onSnapshot((res) => {
                        for (let doc of res.docs) {
                          let pledge = doc.data();
                          pledge.id = doc.id;
                          pledgesArray.push(pledge);
                        }
                      });
                  }
                  this.setState({ pledges: pledgesArray });
                }
              }
            });
        });
      } // end if user
    });
  }

  viewPledgeManager(charity) {
    this.setState({ selectedCharity: charity, view: 'managePledges' });
  }

  viewEditCharity(charity) {
    this.setState({ selectedCharity: charity, view: 'editCharity' });
  }

  charitySelected(charity) {
    this.setState({ selectedCharity: charity });
  }

  viewDashboard(charity) {
    this.setState({ selectedCharity: charity });
    this.setState({ view: 'dashboard' });
  }

  viewCharityList() {
    this.setState({ view: 'charityList' });
  }

  viewAddCharity() {
    this.setState({ view: 'addCharity' });
  }

  viewEditPortfolio() {
    this.setState({ view: 'editPortfolio' });
  }

  viewManagePortfolio() {
    this.setState({ view: 'managePortfolio' });
  }

  viewSearchDonor() {
    this.setState({ view: 'searchDonor' });
  }

  viewSearchCode() {
    this.setState({ view: 'searchCode' });
  }

  viewManageFlagged() {
    this.setState({ view: 'manageFlagged' });
  }

  signOut() {
    auth.signOut();
  }

  sendEmail(pledge) {
    if (process.env.REACT_APP_ENV === 'local') {
      console.log('EMAILS DISABLED IN DEV');
      return;
    }
    const charity = this.findCharityById(pledge.charityId);
    var templateParams = {
      recipient: pledge.donator.email,
      donorName: pledge.donator.name,
      organisation: charity.organisation,
    };
    emailjs
      .send(
        'service_4g539zb',
        'template_ernrfp7',
        templateParams,
        'user_ZZKVmsuCv5tMLu7UkQFJC'
      )
      .then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text);
        },
        function (error) {
          console.log('FAILED...', error);
        }
      );
  }

  handleReceived(pledge) {
    if (!pledge.pledgeReceived) {
      this.sendEmail(pledge);
    }
    db.collection('pledges').doc(pledge.id).update({
      pledgeReceived: !pledge.pledgeReceived,
    });
  }

  handleAbandoned(pledge) {
    db.collection('pledges').doc(pledge.id).update({
      pledgeAbandoned: !pledge.pledgeAbandoned,
      flagged: !pledge.flagged,
    });
  }

  handlePostComment(pledge) {
    db.collection('pledges').doc(pledge.id).update({
      comments: pledge.comments,
      flagged: true,
    });
  }

  handleUpdateUserCharities(id, charities) {
    db.collection('users').doc(id).update({
      charities: charities,
    });
  }

  handleUpdateStatus(id, status) {
    db.collection('charities')
      .doc(id)
      .update({
        participationStatus: {
          status,
          lastModified: serverTimestamp(),
        },
      });
  }

  findCharityById(id) {
    return this.state.charities.find((charity) => {
      return charity.id === id;
    });
  }

  handleEditCharityForm(charity) {
    db.collection('charities').doc(charity.id).set(charity);
    this.setState({ view: 'charityList' });
  }

  render() {
    if (!this.state.user) {
      return <p>Loading...</p>;
    }

    var theView = <p>Loading...</p>;
    switch (this.state.view) {
      case 'charityList':
        theView = (
          <AdminCharityList
            charities={this.state.charities}
            pledges={this.state.pledges}
            handlePledgeView={this.viewPledgeManager}
            handleEditView={this.viewEditCharity}
            handleFilterCharity={this.viewDashboard}
          />
        );
        break;
      case 'managePledges':
        const charityPledges = this.state.pledges.filter(
          (pledge) => pledge.charityId === this.state.selectedCharity.id
        );
        theView = (
          <PledgeManager
            selectedCharity={this.state.selectedCharity}
            charityPledges={charityPledges}
            onReceived={this.handleReceived}
            onAbandoned={this.handleAbandoned}
            handlePostComment={this.handlePostComment}
          />
        );
        break;
      case 'dashboard':
        theView = (
          <Dashboard
            giftTypes={this.state.giftTypes}
            charities={this.state.charities}
            pledges={this.state.pledges}
            charitySelected={this.charitySelected}
            charity={this.state.selectedCharity}
            findCharityById={this.findCharityById}
            user={this.state.user}
          />
        );
        break;
      case 'addCharity':
        theView = (
          <AddCharityForm
            giftTypes={this.state.giftTypes}
            viewDashboard={this.viewDashboard}
          />
        );
        break;
      case 'editPortfolio':
        theView = (
          <EditPortfolio
            user={this.state.user}
            charities={this.state.charities}
            handleUpdateUserCharities={this.handleUpdateUserCharities}
          />
        );
        break;
      case 'managePortfolio':
        theView = (
          <ManagePortfolio
            charities={this.state.charities}
            updateStatus={this.handleUpdateStatus}
          />
        );
        break;
      case 'editCharity':
        theView = (
          <EditCharity
            selectedCharity={this.state.selectedCharity}
            handleSubmit={this.handleEditCharityForm}
          />
        );
        break;
      case 'searchDonor':
        theView = (
          <SearchDonor
            onReceived={this.handleReceived}
            onAbandoned={this.handleAbandoned}
            handlePostComment={this.handlePostComment}
            charities={this.state.charities}
          />
        );
        break;
      case 'searchCode':
        theView = (
          <SearchCode
            onReceived={this.handleReceived}
            onAbandoned={this.handleAbandoned}
            handlePostComment={this.handlePostComment}
            charities={this.state.charities}
            isAdmin={this.state.user.accessLevel === 'admin'}
          />
        );
        break;
      case 'manageFlagged':
        theView = (
          <ManageFlagged
            pledges={this.state.pledges}
            charities={this.state.charities}
          />
        );
        break;
      default:
        break;
    }
    return (
      <>
        <AdminNavBar
          user={this.state.user}
          viewDashboard={this.viewDashboard}
          viewCharityList={this.viewCharityList}
          viewAddCharity={this.viewAddCharity}
          viewEditPortfolio={this.viewEditPortfolio}
          viewManagePortfolio={this.viewManagePortfolio}
          viewSearchDonor={this.viewSearchDonor}
          viewSearchCode={this.viewSearchCode}
          viewManageFlagged={this.viewManageFlagged}
        />
        {theView}
      </>
    );
  }
}

export default AdminContainer;
