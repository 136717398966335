import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as ROUTES from '../../constants/routes.js';
import { db, fv } from '../../Firebase/firebaseConfig.js';

import LoginContainer from '../../LoginContainer/containers/LoginContainer';
import NavBar from '../../NavigationContainer/components/NavBar.js';
import Footer from '../../NavigationContainer/components/Footer.js';
import PageNotFound from '../../HomeContainer/components/PageNotFound.js';
import PageNotAuthorized from '../../HomeContainer/components/PageNotAuthorized.js';
import PledgeContainer from '../../PledgeContainer/containers/PledgeContainer.js';
import HomeContainer from '../../HomeContainer/containers/HomeContainer.js';
import CharityContainer from '../../CharityContainer/containers/CharityContainer.js';
import AboutUsContainer from '../../AboutUsContainer/containers/AboutUsContainer.js';
import FAQContainer from '../../FAQContainer/containers/FAQContainer.js';
import AdminContainer from '../../AdminContainer/containers/AdminContainer.js';
import QRScan from '../../AdminContainer/containers/QRScan';
import PasswordReset from '../../LoginContainer/components/PasswordReset.js';
import LogoutContainer from '../../LogoutContainer/components/Logout.js';
import ScrollToTop from '../../ScrollToTop.js';
import RegisterContainer from '../../RegisterContainer/containers/RegisterContainer';
import PartnerContainer from '../../PartnerContainer/containers/PartnerContainer';
import ProtectedRoute from '../../shared/ProtectedRoute.js';
import GuestRoute from '../../shared/GuestRoute.js';

class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      charities: [],
      filteredCharities: [],
      selectedCharity: null,
      donation: {},
      donator: {
        name: '',
        address: '',
        phone: '',
        email: '',
      },
      confirmationCode: '',
    };
    this.findCharityById = this.findCharityById.bind(this);
    this.charitySelected = this.charitySelected.bind(this);
    this.updateDonator = this.updateDonator.bind(this);
    this.updateDonation = this.updateDonation.bind(this);
    this.updateCharity = this.updateCharity.bind(this);
    this.updateConfirmationCode = this.updateConfirmationCode.bind(this);
    this.postPledge = this.postPledge.bind(this);
    this.resetValues = this.resetValues.bind(this);
    this.filterCharities = this.filterCharities.bind(this);
  }

  componentDidMount() {
    db.collection('charities')
      .get()
      .then((res) => {
        const data = res.docs.map((doc) => {
          let charity = doc.data();
          charity.id = doc.id;
          return charity;
        });
        this.setState({ charities: data });
        this.setState({ filteredCharities: this.filterCharities(data) });
      });
  }

  filterCharities(charitiesArr) {
    return charitiesArr.filter(
      (charity) => charity?.participationStatus?.status !== 'Not Participating'
    );
  }

  resetValues() {
    if (Object.keys(this.state.donation).length > 0) {
      Object.keys(this.state.donation).forEach((key) => {
        this.updateCharity(null);
        let donation = {};
        this.updateDonation(donation);
      });
    } else {
      this.updateCharity(null);
    }
  }

  findCharityById(id) {
    return this.state.charities.find((charity) => {
      return charity.id === id;
    });
  }

  charitySelected(charity) {
    this.setState({ selectedCharity: charity });
  }

  updateDonator(donator) {
    this.setState({ donator: donator });
  }

  updateDonation(newDonation) {
    this.setState({ donation: newDonation });
  }

  updateCharity(charity) {
    this.setState({ selectedCharity: charity });
  }

  updateConfirmationCode(confirmationCode) {
    this.setState({ confirmationCode: confirmationCode });
  }

  postPledge(newDonation, callback) {
    const pledge = {
      charityId: this.state.selectedCharity.id,
      donator: this.state.donator,
      donation: newDonation,
      datePledged: new Date(),
      pledgeReceived: false,
      pledgeAbandoned: false,
    };

    let charity = this.state.selectedCharity;

    var updates = { giftsRequired: {} };

    Object.keys(newDonation).forEach((key) => {
      let donation = parseInt(newDonation[key]);
      if (donation > 0) {
        if (
          typeof charity.giftsRequired[key] === 'string' ||
          charity.giftsRequired[key] instanceof String
        ) {
          updates['giftsRequired'][key] =
            parseInt(charity.giftsRequired[key]) - donation;
        } else {
          updates['giftsRequired'][key] = fv.increment(donation * -1);
        }
      }
    });

    db.collection('pledges')
      .add(pledge)
      .then((res) => {
        let confirmationCode = res.id;
        let doc = db.collection('charities').doc(charity.id);
        doc
          .set(updates, { merge: true })
          .then((res) => {
            this.updateConfirmationCode(confirmationCode);
            callback();
          })
          .catch((e) => {
            console.error('Error updating document: ', e);
          });
      });
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className='main-container'>
            <NavBar />
            <Switch>
              <Route
                exact
                path={ROUTES.LANDING}
                render={() => {
                  return <HomeContainer />;
                }}
              />

              <Route
                exact
                path={ROUTES.PLEDGE}
                render={() => {
                  return (
                    <PledgeContainer
                      charities={this.state.charities}
                      donator={this.state.donator}
                      donation={this.state.donation}
                      confirmationCode={this.state.confirmationCode}
                      selectedCharity={this.state.selectedCharity}
                      postPledge={this.postPledge}
                      findCharityById={this.findCharityById}
                      charitySelected={this.charitySelected}
                      updateDonator={this.updateDonator}
                      updateDonation={this.updateDonation}
                      updateCharity={this.updateCharity}
                      updateConfirmationCode={this.updateConfirmationCode}
                      resetValues={this.resetValues}
                    />
                  );
                }}
              />

              <Route
                exact
                path={ROUTES.CHARITIES}
                render={() => {
                  return (
                    <CharityContainer
                      charities={this.state.filteredCharities}
                      findCharityById={this.findCharityById}
                      updateCharity={this.updateCharity}
                    />
                  );
                }}
              />

              <Route
                exact
                path={ROUTES.ABOUTUS}
                render={() => {
                  return <AboutUsContainer />;
                }}
              />

              <GuestRoute
                exact
                path={ROUTES.LOGIN}
                component={LoginContainer}
              />

              <Route
                exact
                path={ROUTES.RESET}
                render={() => {
                  return <PasswordReset />;
                }}
              />

              <Route
                exact
                path={ROUTES.FAQ}
                render={() => {
                  return <FAQContainer />;
                }}
              />

              <Route exact path={ROUTES.QRSCAN} component={QRScan} />

              <Route
                exact
                path={ROUTES.LOGOUT}
                render={() => {
                  return <LogoutContainer />;
                }}
              />

              <GuestRoute
                exact
                path={ROUTES.REGISTER}
                component={RegisterContainer}
              />

              <ProtectedRoute
                path={ROUTES.ADMIN}
                requiredRoles={['admin', 'scanner']}
                component={AdminContainer}
              />

              <ProtectedRoute
                path={ROUTES.PARTNER}
                requiredRoles={['partner']}
                component={PartnerContainer}
              />

              <Route path='/unauthorized' component={PageNotAuthorized} />
              <Route path={ROUTES.LANDING} component={PageNotFound} />
            </Switch>
          </div>
          <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}

export default MainContainer;
