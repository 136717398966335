import React, { useState } from 'react';
import PledgeList from './PledgeList';
import PledgeCharityDetails from './PledgeCharityDetails.js';

const PledgeManager = ({charityPledges, selectedCharity, onReceived, onAbandoned, handlePostComment}) => {
    const [filter, setFilter] = useState("");

    let filteredPledges = charityPledges;
    if(filter !== "") {
        const upFilter = filter.toUpperCase();
        filteredPledges = charityPledges.filter(pledge => pledge.id.toUpperCase().startsWith(upFilter));   
    }
    const pledgesReceived = filteredPledges.filter(pledge => pledge.pledgeReceived);
    const pledgesNotReceived = filteredPledges.filter(pledge => !pledge.pledgeReceived && !pledge.pledgeAbandoned);
    const pledgesAbandoned = filteredPledges.filter(pledge => pledge.pledgeAbandoned);

    return(
        <>
        <h1>Pledge Manager</h1>
        <PledgeCharityDetails selectedCharity={selectedCharity} />
        <div>
            <label>Filter Pledge Code: </label>
            <input value={filter} onChange={(event) => setFilter(event.target.value)}/>
        </div>
        <PledgeList
            pledgesReceived={pledgesReceived}
            pledgesNotReceived={pledgesNotReceived}
            pledgesAbandoned={pledgesAbandoned}
            onReceived={onReceived}
            onAbandoned={onAbandoned}
            handlePostComment={handlePostComment}/>
        </>
    )

}

export default PledgeManager;
