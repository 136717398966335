import React from 'react';
import { Link } from 'react-router-dom';
import FAQSteps from '../components/FAQSteps';

const FAQ = function () {
  return (
    <div className="FAQ-container">
      <div className="question">
        <h3>1. What is Glasgow's Spirit of Christmas?</h3>
        <p><strong>Glasgow’s Spirit of Christmas</strong> was founded by Julie Griffin in 2014 when she learned of local children suffering hardship or living in poverty who had no gifts to open on Christmas Day. Standing in a vacant car park with just one <strong>SANTA STOP HERE</strong> sign and a few big-hearted friends they were soon joined by hundreds of like-minded people who wanted to help children all around us in local neighbourhoods be included in the magic of Christmas.</p>
        <p><strong>Glasgow’s Spirit of Christmas</strong> children’s gift campaign invites everyone to <strong>Be a Secret Santa</strong> and is organised and managed by Cultivating Mindfulness, a Scottish Charitable Incorporated Organisation (SCIO) Registration Number SC048634.</p>
        <p>Learn more about our charity and what we do throughout the year <a className="inline-link" href="http://www.cultivatingmindfulness.org.uk" aria-label="Learn more at cultivatingmindfulness.org.uk">here</a>.</p>
        <img className="GSoC-image-faq" src="/images/GSOC_2023_Badge.png" alt="GSOC 2023 Badge" />
      </div>
      <div className="question">
        <h3>2. I’d love to get involved, how does it work?</h3>
        <p>If you haven’t already, join our Glasgow’s Spirit of Christmas private Facebook Group. It can be found <a className="inline-link" href="http://www.facebook.com/groups/613947025394004" aria-label="Visit our Facebook group page">here</a>.</p>
        <p>Or our public Facebook page <a className="inline-link" href="https://www.facebook.com/glasgowspiritofchristmas/" aria-label="Visit our Facebook page">here</a>.</p>
        <p>The pinned <strong>"VIEW ANNOUNCEMENTS or FEATURED POST"</strong> at the top of Facebook group or public page explains how the gift campaign works, together with our official list of partnering charities and organisations that refer children in need to us for support at Christmas.</p>
        <p>You’ll find useful resources and other ways to help us <a className="inline-link" href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">here</a>.</p>
      </div>
      <div className="question">
        <h3>3. How do I donate?</h3>
        <p>Become a <strong>Secret Santa</strong> for a child affected by financial hardship or living in poverty. </p>
        <FAQSteps />
      </div>
      <div className="question">
        <h3>4.  What is a Gift Made With Love?</h3>
        <p>A Gift Made with Love is a personal gift you create that includes a selection of gifts relevant to the child’s age and gender and placed in a festive Christmas Gift Bag.</p>
        <p>If you join our Facebook group <a className="inline-link" href="http://www.facebook.com/groups/613947025394004" aria-label="Visit our Facebook group page">here</a> you will be able to look at the <strong>"Photos"</strong> section to see lots of gift ideas from our members to help and inspire you.</p>
        <p>You should write the age and gender of your chosen child on the gift tag along with the Reference Number provided in your confirmation email or show your QR code when you drop off your gifts which will help us to sort and allocate your gifts to the child(ren) you chose.</p>
      </div>
      <div className="question">
        <h3>5. Can I wrap my gifts in wrapping paper?</h3>
        <p>Each charity has to check that gifts are appropriate and for health and safety purposes. We ask that you <strong><u>do not wrap gifts</u></strong> as they will be unwrapped upon receipt. Instead, please use a festive Christmas Gift Bag as detailed above.</p>
      </div>
      <div className="question">
        <h3>6. How much should I spend?</h3>
        <p>We don't stipulate an amount that you should spend per child. Creating a Gift Made with Love is entirely at your own discretion and we are grateful with whatever you wish to donate. All we ask is that you put yourself in the child’s shoes and imagine what will make them smile and feel loved and included on Christmas morning.</p>
        <p>Please download our <strong>Be a Secret Santa – How to Create a Gift Made with Love Guide</strong> guide for ideas and suggestions on our website <a href=" https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="See our guides at cultivatingmindfulness.org.uk" className="inline-link">here</a>.</p>
      </div>
      <div className="question">
        <h3>7. Can I give a gift voucher?</h3>
        <p>Experiences such as cinema, restaurant vouchers or store gift cards can be a great choice for teenagers 13+ and can be included in your gift.</p>
        <p>For younger children we advise that a relevant <strong>Gift made with Love</strong> would be so much more appreciated as so much thought and care would have gone into creating it which a child of this age group will feel and value when they open their gift on Christmas morning.</p>
      </div>
      <div className="question">
        <h3>8. How can I find out the shoe or clothing sizes of the child?</h3>
        <p>We would suggest that the children’s clothes be based on one size up from the age group you have chosen. For teenagers we recommend that adult size small or medium will suit most teenagers 13-16 and medium or large for most 16 plus ages. For example, if your chosen child is age 3-5 years we would advise size 4/5 years clothing to allow for growth.</p>
        <p>We <strong>do not</strong> advise donating shoes as these are very specific and sizes and width can vary.</p>
      </div>
      <div className="question">
        <h3>9. Can I donate nearly new toys or clothing?</h3>
        <p>Due to Health and Safety, we are only able to accept <strong>new</strong> items.</p>
      </div>
      <div className="question">
        <h3>10. Can I include a Christmas card with a message?</h3>
        <p>You <strong>should not</strong> include any personal messages or Christmas cards for children as they expect their gift to have come from Santa, whose role you are kindly stepping in for.</p>
      </div>
      <div className="question">
        <h3>11. Can I volunteer to help?</h3>
        <p>Yes! We have many volunteering opportunities for individuals and companies. You can view our <strong>"Volunteer Roles"</strong> and submit your interest on our Volunteer website page <a href="https://www.cultivatingmindfulness.org.uk/volunteer-with-us" className="inline-link" aria-label="See our volunteers roles at cultivatingmindfulness.org.uk">here</a>.</p>
      </div>
      <div className="question">
        <h3>12. How can my office or company get involved?</h3>
        <p>Our <strong>Company & Office Secret Santa Guide</strong> is available to download directly from our Digital Marketing Resources page or from our website page <a href=" https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="See our guides at cultivatingmindfulness.org.uk" className="inline-link">here</a>.</p>
      </div>
      <div className="question">
        <h3>13. How can I get support for my organisation/community group?</h3>
        <p>Please send us an email to glasgowspiritofchristmas@gmail.com if you would like to apply for support. Our charity and children’s organisation partners must meet specific criteria and we cannot guarantee that your application will be successful..</p>
      </div>
      <div className="question">
        <h3>14. How can I apply for gifts for my family?</h3>
        <p>Unfortunately we do not accept applications from individuals or families at this time. If you need support, please have a look at our list of <Link to="/charities" className="inline-link">Participating Charities</Link> and contact the most relevant or appropriate organisation for your circumstances who may be able to help and refer you to us for gift support.</p>
      </div>
    </div>
  )
}

export default FAQ;
