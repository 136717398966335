import React, { useState, useEffect } from 'react';
import {db} from '../../Firebase/firebase'
import { auth } from '../../Firebase/firebase.js';

const QRScan = (props) => {
  const code = props.match.params["id"];
  const [pledge, setPledge] = useState(null);
  const [charity, setCharity] = useState(null);
  const [received, setReceived] = useState(false);

  //IS ADMIN?
  useEffect(() => {
    setReceived(false);
    auth.onAuthStateChanged( user => {
      if(!user) {
        props.history.push("/login")
      } else {
        db.collection("pledges").doc(code).get()
          .then(record => {
            if(record.exists) {
              setPledge(record.data());
              db.collection("charities").doc(record.data().charityId).get()
                .then(record => {
                  setCharity(record.data());
                }).catch(error => console.log("Load Charity Error", error));
            }
          }).catch(error => console.log("Load Pledge Error", error));
      };
    });
  }, [props.history, code]);

  const handleReceive = () => {
    db.collection("pledges").doc(code).update({
      pledgeReceived: true
    }).then(res => {
      setReceived(true);
    })
  };

  const shortcode = code.toUpperCase().slice(0,6);
  if(received) {
    return (<div class="qrcode-receive">
      <div className="code">
        <h1>{shortcode}</h1>
        <p>Received</p>
        <p className="ok">OK</p>
      </div>
    </div>);
  }

  //DID LOOKUP?
  if(!pledge) {
    return (<>
    <h1>{code}</h1>
    <p>Code was not found.  Please Rescan.</p>
    </>)
  } else {
    const donations = [];
    for(let donation in pledge.donation) {
      donations.push({type: donation, amount: pledge.donation[donation]});
    };
    return (<div className="qrcode-receive">
      <div className="code">
        <h1>{shortcode}</h1>
        <p>Pledge Found</p>
      </div>
      <div className="donor">
        <h2>Donor</h2>
        <p><strong>Name:</strong> {pledge.donator.name}</p>
        <p><strong>Email:</strong> {pledge.donator.email}</p>
        <p><strong>Address:</strong> {pledge.donator.address}</p>
      </div>
      <div className="donation">
        <h3>Donation:</h3>
        <ul>
          {donations.map(donation => <li key={donation.type}>{donation.type}: <strong>{donation.amount}</strong></li>)}
        </ul>
      </div>
      {charity && <div className="charity">
        <h3>Charity</h3>
        <p>{charity.organisation}</p>
      </div>}
      <div className="actions">
        <button onClick={handleReceive}>Mark Received</button>
      </div>
    </div>);
  }
};

export default QRScan;