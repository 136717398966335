import React from 'react';

class PledgeType extends React.Component {
  donateCash(){
    window.location.href="https://www.justgiving.com/campaign/glasgows-spirit-of-christmas-2023";
  }

  render(){
    return (
      <React.Fragment>
        <h1>Pledge a Donation</h1>
        <div className="vertical-center-align">
          <button onClick={this.props.pledgeSelected} className="button-red-large">I'd like to donate a gift</button>
          <button onClick={this.donateCash} className="button-grey-large">I'd like to donate cash</button>
        </div>
      </React.Fragment>
    )
  }
}

export default PledgeType;
