import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";

const ShareSocial = (props) => (

  <div className="share">
    <FacebookShareButton
      url={props.url}
      quote={props.quote}
      className="about-share">
      <FacebookIcon size={50} round />
    </FacebookShareButton>

    <TwitterShareButton
      url={props.url}
      quote={props.quote}
      className="about-share">
      <TwitterIcon size={50} round />
    </TwitterShareButton>

    <a href="https://www.instagram.com/glasgows_spirit_of_christmas/" aria-label="Visit our Instagram page">
      <img width={50} height={50} src="images/insta_logo.png" alt="Instagram Logo"/>
    </a>

  </div>

);

export default ShareSocial;
