import React, { Children } from 'react'

import './BackdropModal.scss'

class BackdropModal extends React.Component {
    render() {
        return (
            <div className="backdrop-modal-container">
                <div className="backdrop-modal">
                    {this.props.children}
                </div>
            </div>
        );
    }
};

export default BackdropModal;