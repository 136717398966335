import React from "react";

import { auth } from "../../Firebase/firebase";
import RegisterForm from "../components/RegisterForm";

class RegisterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // uniqueId: null,
      error: null,
    };
  }

  render() {
    return (
      <>
        <RegisterForm />
        {this.state.error && <p>{this.state.error.message}</p>}
      </>
    );
  }
}

export default RegisterContainer;
