import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes.js'

const PageNotAuthorized = function(){
  return (
    <React.Fragment>
      <h1>Not Authorized</h1>
      <p>Oops!  Looks like you don't have permissions to access this page.</p>
      <Link to={ROUTES.LANDING} className="home-button">Home</Link>
    </React.Fragment>
  )
}

export default PageNotAuthorized;
