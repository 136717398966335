import React, { useState } from 'react';
import Button from '../../Components/Button/Button';
import Form from '../../Components/Form/Form';

const flagReasons = [
  "Donor circumstances changed",
  "Donor didn't check delivery info",
  "Donor pledging to another charity",
  "Other",
];

const FlagPledge = (props) => {

  const hasComment = props.comments && props.comments.length > 0;
  const selectedOption = hasComment ? (flagReasons.includes(props.comments[0]) ? props.comments[0] : "Other") : undefined;
  const customFlag = selectedOption === "Other" ? props.comments[0] : "";

  const [ showFlagSelection, setShowFlagSelection ] = useState(false);
  const [ selectedMessage, setSelectedMessage ] = useState(selectedOption);
  const [ customMessage, setCustomMessage ] = useState(customFlag);

  const handleChange = event => {
    if (event.target.value !== "Other") setCustomMessage("");
    setSelectedMessage(event.target.value);
  };

  const handleCancel = () => {
    setShowFlagSelection(false);
    setSelectedMessage(hasComment ? selectedOption : undefined);
    setCustomMessage(hasComment ? customFlag : "");
  };

  const postComment = event => {
    event.preventDefault();
    if (!selectedMessage || (selectedMessage === "Other" && customMessage === "")) return;
    const comment = selectedMessage === "Other" && customMessage.trim().length > 0 ? customMessage : selectedMessage;
    const newPledge = {...props.pledge, comments: [comment], flagged: true};
    props.handlePostComment(newPledge);
    setShowFlagSelection(false);
  };

  return hasComment && !showFlagSelection ? (
      <div>
        <p><strong>Flag: </strong>{props.pledge.comments[0]}</p>
        <Button variant="red" type="button" onClick={() => setShowFlagSelection(true)}>
          Edit flag
        </Button>
      </div>
    ) : (
      <Form onSubmit={postComment} className="vertical-center-align">
        {!showFlagSelection ? (
          <Button variant="red " onClick={() => setShowFlagSelection(true)} type="button">
            Flag Pledge
          </Button>
        ) : (
          <>
            <label className="vertical-center-align flag-select">
              Please provide a reason for flagging this pledge:
              <select onChange={handleChange} value={selectedMessage}>
                <option value="Please select a reason" disabled selected>Please select a reason</option>
                {flagReasons.map(reason => (
                  <option value={reason} key={reason}>{reason}</option>
                ))}
              </select>
            </label>
            {selectedMessage === "Other" && (
              <textarea
                rows={3}
                type="text" 
                value={customMessage} 
                placeholder="Type your comment here... (max 270 characters)" 
                onChange={event => setCustomMessage(event.target.value)} 
              />
            )}
            <div>
              <Button variant="outlined" type="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant="red" type="submit">
                Confirm
              </Button>
            </div>
          </>
        )}
      </Form>
    )
}

export default FlagPledge;
