import React from 'react';
import Pledge from './Pledge';

const PledgeList = ({pledgesReceived, pledgesNotReceived, pledgesAbandoned, onReceived, onAbandoned, handlePostComment}) => {

    return(
        <>
            <h2 className="pledge-manager-subheading">Pledges To Be Received</h2>
            <ul>
                {pledgesNotReceived.map(pledge => (
                    <li key={pledge.id}><Pledge pledge={pledge} onReceived={onReceived} handlePostComment={handlePostComment} /></li>
                ))}
            </ul>

            <hr />

            <h2 className="pledge-manager-subheading">Pledges Confirmed Received</h2>
            <ul>
                {pledgesReceived.map(pledge => (
                    <li key={pledge.id}><Pledge pledge={pledge} onReceived={onReceived} handlePostComment={handlePostComment} /></li>
                ))}
            </ul>

            <hr />

            <h2 className="pledge-manager-subheading">Abandoned Pledges</h2>
            <ul>
                {pledgesAbandoned.map(pledge => (
                    <li key={pledge.id}><Pledge pledge={pledge} onAbandoned={onAbandoned} handlePostComment={handlePostComment} /></li>
                ))}
            </ul>
        </>
    )

}

export default PledgeList;
