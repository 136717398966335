import React, { useState } from "react";
import FlagPledge from "./FlagPledge";
import Button from "../../Components/Button/Button";

const Pledge = props => {
    const { pledge, onAbandoned, onReceived, handlePostComment, charity, isAdmin } = props;
    const [ statusMessage, setStatusMessage ] = useState("");

    const handleClick = async () => {
        if (pledge.pledgeAbandoned) {
            onAbandoned(pledge);
        } else {
            const isReqSuccessful = await onReceived(pledge);
            const successMessage = pledge.pledgeReceived ? `You have marked ${pledge.id} gift as Not Received.` : `Thank you for marking ${pledge.id} gift as received.`
            setStatusMessage(isReqSuccessful ? successMessage : "Something's gone wrong. Please try again.")
        };
    };

    const buttonText = pledge.pledgeReceived ? "Mark as Not Received" : pledge.pledgeAbandoned ? "Mark as Unabandoned" : "Mark as received"

    return (
        <div className="admin-pledge-container">
            <h4 className="pledge-header">{pledge.id}</h4>
            <p><strong>Date Pledged:</strong> {new Date(pledge.datePledged.seconds * 1000).toLocaleDateString("en-GB")}</p>
            {charity && <p><strong>Charity</strong>: {charity.organisation}</p>}
            <p><strong>Donator: </strong>{pledge.donator.name}</p>
            {isAdmin && <p><strong>Donor Phone number:</strong>{pledge.donator.phone}</p>}
            {isAdmin && <p><strong>Donor Email:</strong>{pledge.donator.email}</p>}
            <h3>Pledge Details</h3>
            {Object.keys(pledge.donation).map(donationKey => (
                <div key={donationKey}>
                    <p>{`${pledge.donation[donationKey]} x ${donationKey}`}</p>
                </div>
            ))}
            <Button 
                type="button"
                variant={pledge.pledgeReceived || pledge.pledgeAbandoned ? "outlined" : "red"} 
                onClick={handleClick}
                disabled={!pledge.pledgeReceived && !pledge.pledgeAbandoned && pledge.flagged}
            >
                {buttonText}
            </Button>

            {statusMessage &&
                <div role="status" className="status-message">{statusMessage}</div>
            }
            
            <FlagPledge pledge={pledge} handlePostComment={handlePostComment} comments={pledge.comments} />
        </div>
    );
};

export default Pledge;