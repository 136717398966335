import React, { useState } from 'react';
import Charity from './Charity.js'

export const requiresPledges = charity => {
  return Object.values(charity.giftsRequired).some(requiredNumber => requiredNumber !== 0);
}

export const isParticipating = charity => {
  return charity.participationStatus.status === "Participating";
}

const Search = (props) => {
  return (
    <div className="postcode-search-filter-wrapper">
      <label htmlFor="postcode-search-filter">Filter charities by postcode:</label>
      <input className='postcode-search-filter' id="postcode-search-filter" value={props.searchString} onChange={e => props.setSearchString(e.target.value.toLocaleUpperCase())} placeholder="eg. G1" />
    </div>
  );
}


const CharityList = (props) => {
  const [searchString, setSearchString] = useState('');

  //sorted by 1. requires gifts, 2. is participating and 3. alphabetical
  const sortedCharities = props.charities.sort((a, b) => {
    if ((requiresPledges(a) && isParticipating(a)) && (!requiresPledges(b) || !isParticipating(b))) return -1;
    else if ((!requiresPledges(a) || !isParticipating(a)) && (requiresPledges(b) && isParticipating(b))) return 1;
    else if (isParticipating(a) && !isParticipating(b)) return -1;
    else if (!isParticipating(a) && isParticipating(b)) return 1;
    else return a.organisation.localeCompare(b.organisation);
  });

  const postCodeFilter = (charity) => {
    const normalisedSearchString = searchString.toLocaleUpperCase().replace(/\s+/g, '');
    const normalisedPostCode = charity.postCode.toLocaleUpperCase().replace(/\s+/g, '');
    // No search string or postcode, no filtering
    if(!normalisedSearchString || !normalisedPostCode) {
      return true;
    }
    // Charity includes substring
    if(normalisedPostCode.indexOf(normalisedSearchString) === -1) {
      return false;
    }
    return true;
  }

  const charityItems = sortedCharities.filter(postCodeFilter).map((charity, index) => {
      return (
          <li key={index}>
            <Charity charity={charity} findCharityById={props.findCharityById} updateCharity={props.updateCharity} />
          </li>
      )
  })

  return (
    <div>
      <Search searchString={searchString} setSearchString={setSearchString} />
      <ul className="charity-list">{charityItems}</ul>
      {searchString && charityItems.length === 0 && <p>No matching charities found for <strong>{searchString}</strong>. Try searching for part of a postcode, eg. G1</p>}
    </div>
  )
}

export default CharityList;
