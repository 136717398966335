import React from "react";
import bcrypt from "bcryptjs";

import LoginForm from "../components/LoginForm";

import { auth } from "../../Firebase/firebase";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // uniqueId: null,
      errorMessage: null,
    };
    this.signIn = this.signIn.bind(this);
  }

  async signIn(userDetails) {
    try {
      // Set users persistence based if the user checked the remember-me checkbox
      auth.setPersistence(userDetails["remember-me"] ? "local" : "session");

      // Fetch the user from Firebase
      const userCredential = await auth.signInWithEmailAndPassword(
        userDetails.email,
        userDetails.password
      );

      return userCredential.user;
    } catch (error) {
        this.setState({errorMessage: "Incorrect email or password."});
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <LoginForm signIn={this.signIn} error={this.state.errorMessage} />
      </React.Fragment>
    );
  }
}

export default LoginContainer;
