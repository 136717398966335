import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebaseConfig.js";
import { giftTypes } from "../../constants/giftTypes.js";
import { ToastContainer, toast } from "react-toastify";
import { serverTimestamp } from "firebase/firestore";

import Form from "../../Components/Form/Form.js";
import Button from "../../Components/Button/Button.js";
import BackdropModal from "../../Components/BackdropModal/BackdropModal.js";
import SaveModal from "./SaveModal.js";

import "./PartnerDashboard.scss";
import "react-toastify/dist/ReactToastify.css";

function PartnerWishlist({ selectedCharity }) {
  let initialState = {
    charity: {
      organisation: "",
      addressLine1: "",
      addressLine2: "",
      location: "",
      postCode: "",
      openingDetails: {
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
      },
      dropoffWindow: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      publicContactEmail: "",
      canEditWishList: "",
    },
    errorMessage: null,
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let charityData;

    const fetchData = async () => {
      await db
        .collection("charities")
        .where("userId", "==", selectedCharity)
        .onSnapshot((res) => {
          const dbUser = res.docs[0];
          charityData = dbUser.data();

          setFormData({ charity: charityData });
          setLoading(false);
        });
    };

    fetchData();
  }, [selectedCharity]);

  const handleUpdateCharity = async (charityDetails) => {
    console.log("charityDetails", charityDetails);
    await db.collection("charities").doc(charityDetails.id).update({
      canEditWishList: false,
    });
    await db.collection("charities").doc(charityDetails.id).set(charityDetails);
  };

  const handleChangeGift = (event) => {
    let propertyName = event.target.name;
    let charity = formData.charity;
    charity.giftsRequired[propertyName] = parseInt(event.target.value);
    setFormData({ charity: charity });
  };

  const handleInternalSubmit = (event) => {
    event.preventDefault();

    if (!formData.charity.canEditWishList) {
      setFormData((prevData) => {
        let newState = { ...prevData };
        newState.errorMessage =
          "Something wrent wrong, please refresh the page and try again!";

        setShowModal(false);
        return newState;
      });

      return;
    }

    let charity = formData.charity;
    try {
      setFormData((prevData) => {
        let newState = { ...prevData };
        newState.charity.canEditWishList = false;
        newState.wishlistLastModified = serverTimestamp();
        delete newState.errorMessage;
        return newState;
      });

      setShowModal(false);
      handleUpdateCharity(formData.charity);
      savedNotification();
    } catch (error) {
      setFormData((prevData) => {
        let newState = { ...prevData };
        newState.errorMessage =
          "Please complete all fields before attempting to save the wishlist.";

        setShowModal(false);
        return newState;
      });
    }
  };

  const handleSaveChangesButton = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleCancelSubmit = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const wishListModalContent = (
    <>
      <h2>Alert</h2>

      <div className="modal-body">
        <p>You are about to save your required gifts.</p>
        <p>
          <strong>
            Once you click Save, you won't be able to edit your gift
            requirements online for this year.
          </strong>
        </p>
        <p>How would you like to proceed?</p>
      </div>
    </>
  );

  const savedNotification = () =>
    toast("Saved successfully!", {
      type: "success",
    });

  if (loading) {
    return <div>Loading...</div>;
  } else {
    const giftsRequiredFormFields = giftTypes.map((giftType) => {
      const value = formData.charity.giftsRequired[giftType];
      return (
        <div className="add-charity-gift-input" key={giftType}>
          <label htmlFor={giftType}>{giftType}</label>
          <input
            type="number"
            name={giftType}
            id={giftType}
            onChange={handleChangeGift}
            value={value}
            required
            min={0}
            max={999}
            disabled={!formData.charity.canEditWishList}
          />
        </div>
      );
    });

    const lastModifiedDate =
      formData.charity?.wishlistLastModified &&
      new Date(formData.charity?.wishlistLastModified?.seconds * 1000);
    const lastModifiedDateTime =
      lastModifiedDate &&
      `${lastModifiedDate.toLocaleDateString()} ${lastModifiedDate.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      )}`;

    return (
      <div className="partner-dashboard">
        {lastModifiedDate && (
          <div className="public-contact">
            <strong>Last modified:</strong> {lastModifiedDateTime}
          </div>
        )}

        <ToastContainer />
        <BackdropModal>
          <Form onSubmit={handleSaveChangesButton}>
            <h1>Charity Wish List</h1>

            {!formData.charity.canEditWishList && (
              <p className="backdrop-modal-sub-header">
                <strong>
                  You can not edit your gift requirements online for this year.
                  <br />
                  If you want to edit your gift requirements contact
                  <br />
                  <div style={{ textDecoration: "underline" }}>
                    glasgowspiritofchristmas@gmail.com
                  </div>
                </strong>
              </p>
            )}

            {giftsRequiredFormFields}

            <p className="red-text">{formData.errorMessage}</p>

            <Button type="submit" disabled={!formData.charity.canEditWishList}>
              Save Changes
            </Button>
            <br />
          </Form>

          <SaveModal
            isOpen={showModal}
            onConfirm={handleInternalSubmit}
            onCancel={handleCancelSubmit}
          >
            {wishListModalContent}
          </SaveModal>
        </BackdropModal>
      </div>
    );
  }
}

export default PartnerWishlist;
