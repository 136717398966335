import React from 'react';
import AboutText from '../components/AboutText.js';
import ShareContainer from '../../ShareContainer/containers/ShareContainer.js';


const AboutUs = () => (

  <div className="about-container">
    <AboutText />

    <ShareContainer />
  </div>

);


export default AboutUs;
