import React from "react";
import { NavLink } from "react-router-dom";

import * as ROUTES from "../../constants/routes.js";
import Button from "../../Components/Button/Button.js";
import { auth } from "../../Firebase/firebase";

import "./NavBar.scss";
import DashboardButton from "./DashboardButton.js";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.expandNavBar = this.expandNavBar.bind(this);
    this.state = {
      user: false,
    };
  }

  componentDidMount() {
    this.listener = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: true });
      }
    });
  }

  expandNavBar() {
    let mobileLinks = document.getElementById("navigation-links");
    let menuButton = document.getElementById("menu-button");

    menuButton.classList.toggle("change");

    if (mobileLinks.style.display === "block") {
      mobileLinks.style.display = "none";
    } else {
      mobileLinks.style.display = "block";
    }
  }

  render() {
    const SignInButton = () => {
      if (!this.state.user) {
        return (
          <NavLink to={ROUTES.LOGIN}>
            <Button variant="outlined">Login/Register</Button>
          </NavLink>
        );
      }

      return (
        <NavLink to={ROUTES.LOGOUT}>
          <Button variant="outlined">Logout</Button>
        </NavLink>
      );
    };

    return (
      <React.Fragment>
        <header id="header" className="nav-bar">
          <div className="nav-bar-container">
            <div className="nav-bar-left">
              <NavLink
                className="nav-link nav-logo"
                to={ROUTES.LANDING}
                exact={true}
              >
                <img
                  className="logo-image"
                  src="/images/GSOC_2023_Logo.svg"
                  alt="Glasgow's Spirit of Christmas Logo"
                  height="57px"
                />
              </NavLink>

              <NavLink className="nav-link" to={ROUTES.LANDING} exact={true}>
                Home
              </NavLink>
              <NavLink className="nav-link" to={ROUTES.ABOUTUS}>
                Resources
              </NavLink>
              <NavLink className="nav-link" to={ROUTES.CHARITIES}>
                Participating Charities
              </NavLink>
              <NavLink className="nav-link" to={ROUTES.FAQ}>
                FAQ
              </NavLink>
            </div>
            <div className="nav-bar-right">
              <DashboardButton />
              <SignInButton />
              <NavLink to={ROUTES.PLEDGE}>
                <Button variant="red">Donate</Button>
              </NavLink>
            </div>
          </div>
        </header>
        <div className="nav-bar-container-mobile">
          <div className="top-nav-bar-mobile">
            <NavLink
              className="nav-link-mobile"
              to={ROUTES.LANDING}
              exact={true}
            >
              Glasgow's Spirit of Christmas
            </NavLink>
            <button
              className="nav-icon-mobile"
              id="menu-button"
              onClick={this.expandNavBar}
            >
              <div className="hamburger-menu-line-1"></div>
              <div className="hamburger-menu-line-2"></div>
              <div className="hamburger-menu-line-3"></div>
            </button>
          </div>
          <div className="nav-bar-links-mobile" id="navigation-links">
            <NavLink
              className="nav-link-mobile"
              to={ROUTES.ABOUTUS}
              onClick={this.expandNavBar}
            >
              Resources
            </NavLink>
            <NavLink
              className="nav-link-mobile"
              to={ROUTES.CHARITIES}
              onClick={this.expandNavBar}
            >
              Charities
            </NavLink>
            <NavLink
              className="nav-link-mobile"
              to={ROUTES.FAQ}
              onClick={this.expandNavBar}
            >
              FAQ
            </NavLink>
            <NavLink
              className="nav-link-mobile"
              to={ROUTES.PLEDGE}
              onClick={this.expandNavBar}
            >
              Pledge a Donation
            </NavLink>
            <DashboardButton />
            <SignInButton />
            <NavLink to={ROUTES.PLEDGE}>
              <Button variant="red">Donate</Button>
            </NavLink>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NavBar;
