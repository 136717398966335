import React, { useState, useEffect, useCallback } from 'react';
import { giftTypes, giftTypeImages } from '../../constants/giftTypes.js';

const MaxReachedMessage = ({ maxVal, current }) => {
  const max = parseInt(`${maxVal}`);
  if (max === current) {
    return <h5>Maximum Pledges Reached</h5>;
  }
  return null;
};

const GiftForm = ({ charity, newDonation, updateNewDonation }) => {
  const [donationsState, setDonationsState] = useState(newDonation);

  const clearDonations = useCallback(() => {
    setDonationsState({});
    updateNewDonation({});
  }, [setDonationsState, updateNewDonation]);

  useEffect(() => {
    clearDonations();
  }, [charity, clearDonations]);

  const handleAdd = (key, maxVal) => {
    let updatedDonations = { ...donationsState };
    const max = parseInt(`${maxVal}`);

    if (!updatedDonations[key] || updatedDonations[key] !== max) {
      if (!updatedDonations[key]) {
        updatedDonations[key] = 1;
      } else {
        updatedDonations[key] += 1;
      }
    }
    setDonationsState(updatedDonations);
    updateNewDonation(updatedDonations);
  };

  const handleRemove = (key) => {
    let updatedDonations = { ...donationsState };
    if (updatedDonations[key] > 0) {
      updatedDonations[key] -= 1;
    }
    if (updatedDonations[key] === 0) {
      delete updatedDonations[key];
    }
    setDonationsState(updatedDonations);
    updateNewDonation(updatedDonations);
  };

  var updatedOrderedOptions = {};
  giftTypes.forEach((option) => {
    var valueToSet = charity.giftsRequired[option];
    updatedOrderedOptions[option] = valueToSet;
  });

  const giftTypeOptions = Object.keys(updatedOrderedOptions).map(
    (key, index) => {
      if (charity.giftsRequired[key] > 0 || donationsState[key]) {
        const setAmount = donationsState[key] || 0;
        let cssclass = 'gift-form';

        if (setAmount > 0) {
          cssclass += ' selected';
        }

        return (
          <div className={cssclass} key={index}>
            <img
              onClick={() => {
                handleAdd(key, charity.giftsRequired[key]);
              }}
              src={'/assets/' + giftTypeImages[key] + '.svg'}
              alt={key}
            />
            <h4>{key}</h4>
            <span>
              {charity.giftsRequired[key]}{' '}
              {charity.giftsRequired[key] === 1 ? 'child' : 'children'}
            </span>
            <br />
            <br />
            <button
              onClick={() => {
                handleAdd(key, charity.giftsRequired[key]);
              }}
            >
              Add
            </button>
            <button
              onClick={() => {
                handleRemove(key);
              }}
            >
              Remove
            </button>
            <br />
            <br />
            <strong className='qty'>
              Pledging for {setAmount}{' '}
              {donationsState[key] === 1 ? 'child' : 'children'}
            </strong>
            <br />
            <MaxReachedMessage
              maxVal={charity.giftsRequired[key]}
              current={setAmount}
            />
          </div>
        );
      } else {
        return null;
      }
    }
  );

  return (
    <>
      <h1>Pledge a Donation</h1>
      <div className='gift-form'>
        <div className='vertical-center-align'>
          <h3>Add Number of Children</h3>
          <div className='gift-options'>{giftTypeOptions}</div>
          {/* <button onClick={()=> {handleFormSubmit()}}> Submit</button> */}
          {/* Handled by continue in PledgeContainer.js::donationCreated for now */}
        </div>
      </div>
    </>
  );
};

export default GiftForm;
