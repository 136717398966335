import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./useAuth";

const ProtectedRoute = ({ component: Component, requiredRoles, ...rest }) => {
    const { user, loading } = useAuth();

    if (loading) {
        // Display loading indicator
        return <div>Loading...</div>;
    }

    if (!user) {
        // User not authenticated, redirect to login
        return <Redirect to="/login" />;
    }

    if (requiredRoles && !requiredRoles.includes(user.role)) {
        // User role does not match the required roles, redirect to a unauthorized page
        return <Redirect to="/unauthorized" />;
    }
    
    // User is authenticated and has required role, render the component
    return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default ProtectedRoute;