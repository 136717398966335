import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { requiresPledges, isParticipating } from "./CharityList.js";
import CharityInfo from './CharityInfo.js'

const Charity = ({ charity, findCharityById, updateCharity }) => {
  const [ showInfo, setShowInfo ] = useState(false);

  const handleMakePledge = () => {
    const charityToUpdate = findCharityById(charity.id);
    updateCharity(charityToUpdate);
  };

  const quotaMet = !requiresPledges(charity);

  return (
    <div className="charity-container">
      <div className="charity-top">
        <div className="charity-left">
          <h4 className="charity-header">{charity.organisation}</h4>
          <p className="charity-location-text">{charity.location}</p>
        </div>
        <div className="charity-right">
          <button className="button-grey-small" onClick={() => setShowInfo(!showInfo)}>More Info</button>
          {!quotaMet && isParticipating(charity) ? (
            <Link to="/pledge"><button className="button-red-small" onClick={handleMakePledge}>Make a Pledge</button></Link>
          ) : (
            <small>{!isParticipating(charity) ? "Application pending" : "Quota fulfilled."}</small>
          )}
        </div>
      </div>
      <div className="charity-bottom">
        {showInfo && <CharityInfo charity={charity} toggleInfo={() => setShowInfo(false)}/>}
      </div>
    </div>
  );
};

export default Charity;
