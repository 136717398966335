import React, {useState} from "react";

import {Link} from "react-router-dom";

import {auth} from '../../Firebase/firebase';
import Form from "../../Components/Form/Form";
import Button from "../../Components/Button/Button";
import BackdropModal from "../../Components/BackdropModal/BackdropModal";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = event => {
    event.preventDefault();
    auth
    .sendPasswordResetEmail(email)
    .then(() => {
      setEmailHasBeenSent(true);
      setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
    })
    .catch(() => {
      setError("Error resetting password");
    });
};
  return (
    <BackdropModal>
      <h1> Reset your Password </h1>
      <div>
        <Form action="">
          {emailHasBeenSent && (
            <div>
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div>
              {error}
            </div>
          )}
          <label htmlFor="userEmail"> Email: </label>
          <input
            type="email"
            name="userEmail"
            id="userEmail"
            value={email}
            placeholder="Input your email"
            onChange={onChangeHandler}
            />
          <Button onClick={sendResetEmail}> Send me a reset link </Button>
        </Form>
        <h3> Click the link below to return to the sign-in page </h3>
        <Link to ="/login">
          &larr; back to sign in page
        </Link>
      </div>
    </BackdropModal>
  );
};
export default PasswordReset;