import React from 'react';

class EditPortfolio extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      charities: this.props.user.charities,
      confirmationMessage: null
    }
    this.handleCharityChange = this.handleCharityChange.bind(this);
    this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
  }

  handleCharityChange(event){
    let charities = this.state.charities;
    if(charities.includes(event.target.value)){
      charities.splice(charities.indexOf(event.target.value), 1)
    }else{
      charities.push(event.target.value)
    }
    this.setState({charities: charities})
  }

  handleSubmitUpdate(event){
    event.preventDefault();
    this.props.handleUpdateUserCharities(this.props.user.userID, this.state.charities)
    this.setState({confirmationMessage: "Success!  Your portfolio has been saved."})
  }

  render(){
    const charityBoxes = this.props.charities.sort((l, r) => { 
      var textA = l.organisation.toUpperCase();
      var textB = r.organisation.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }).map((charity) => {
      if(this.props.user.charities.includes(charity.id)){
        return (
          <div className="portfolio-checkbox" key={charity.id}>
            <input className="checkbox" defaultChecked type="checkbox" id={charity.id} name={charity.id} value={charity.id} onChange={this.handleCharityChange} />
            <label htmlFor={charity.id}>{charity.organisation}</label>
          </div>
        )
      }else{
        return (
          <div className="portfolio-checkbox" key={charity.id}>
            <input className="checkbox" type="checkbox" id={charity.id} name={charity.id} value={charity.id} onChange={this.handleCharityChange} />
            <label htmlFor={charity.id}>{charity.organisation}</label>
          </div>
        )
      }
    })

    return (
      <>
        <h1>Edit Portfolio</h1>
        <div className="portfolio-container">
          <form className="portfolio-form" onSubmit={this.handleSubmitUpdate}>
            {charityBoxes}
            <br />
            <button className="button-red-small">Save Changes</button>
          </form>
          <p>{this.state.confirmationMessage}</p>
        </div>
      </>
    )
  }
}

export default EditPortfolio;
