import React from 'react';

const PledgeCharityDetails = (props) => {
  return (
    <div className="pledge-charity-details">
      <h2 className="pledge-manager-subheading">Charity Details</h2>
      <div className="admin-pledge-container">
        <h4>{props.selectedCharity.organisation}</h4>
        <p><strong>Drop-Off Window:</strong> {props.selectedCharity.dropoffWindow}</p>
        <p><strong>Opening Hours</strong></p>
        <p>Monday: {props.selectedCharity.openingDetails.monday}</p>
        <p>Tuesday: {props.selectedCharity.openingDetails.tuesday}</p>
        <p>Wednesday: {props.selectedCharity.openingDetails.wednesday}</p>
        <p>Thursday: {props.selectedCharity.openingDetails.thursday}</p>
        <p>Friday: {props.selectedCharity.openingDetails.friday}</p>
        <p>Saturday: {props.selectedCharity.openingDetails.saturday}</p>
        <p>Sunday: {props.selectedCharity.openingDetails.sunday}</p>
        <p></p>
      </div>
    </div>
  )
}

export default PledgeCharityDetails;
