import React, {Component, Fragment} from 'react';
import Home from '../components/Home.js';

class HomeContainer extends Component{

render(){
  return (
    <Fragment>
      <Home />
    </Fragment>
    )
  }
};
export default HomeContainer;
