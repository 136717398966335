import React from 'react';
import { giftTypes } from '../../constants/giftTypes.js';

class EditCharity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      charity: {
        organisation: '',
        addressLine1: '',
        addressLine2: '',
        location: '',
        postCode: '',
        giftsRequired: {},
        openingDetails: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
        dropoffWindow: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        publicContactEmail: '',
      },
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOpening = this.handleChangeOpening.bind(this);
    this.handleChangeGift = this.handleChangeGift.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let charity = this.props.selectedCharity;
    this.setState({ charity: charity });
  }

  handleChange(event) {
    let propertyName = event.target.name;
    let charity = this.state.charity;
    charity[propertyName] = event.target.value;
    this.setState({ charity: charity });
  }

  handleChangeOpening(event) {
    let propertyName = event.target.name;
    let charity = this.state.charity;
    charity.openingDetails[propertyName] = event.target.value;
    this.setState({ charity: charity });
  }

  handleChangeGift(event) {
    let propertyName = event.target.name;
    let charity = this.state.charity;
    charity.giftsRequired[propertyName] = parseInt(event.target.value);
    this.setState({ charity: charity });
  }

  handleSubmit(event) {
    event.preventDefault();
    let charity = this.state.charity;
    if (
      !charity.organisation ||
      !charity.addressLine1 ||
      !charity.addressLine2 ||
      !charity.location ||
      !charity.postCode ||
      !charity.contactName ||
      !charity.contactEmail ||
      !charity.contactPhone
    ) {
      this.setState({
        errorMessage:
          'Please complete all fields before attempting to add the charity.',
      });
    } else {
      Object.keys(this.state.charity.giftsRequired).forEach((key) => {
        console.log(
          'saving',
          key,
          charity.giftsRequired[key],
          'as',
          parseInt(charity.giftsRequired[key])
        );
        charity.giftsRequired[key] = parseInt(charity.giftsRequired[key]);
      });
      this.props.handleSubmit(charity);
    }
  }

  render() {
    const giftsRequiredFormFields = giftTypes.map((giftType) => {
      const value = this.state.charity.giftsRequired[giftType];
      return (
        <div className='add-charity-gift-input' key={giftType}>
          <label htmlFor={giftType}>{giftType}</label>
          <input
            type='number'
            name={giftType}
            id={giftType}
            onChange={this.handleChangeGift}
            value={value}
          />
        </div>
      );
    });

    return (
      <div>
        <form className='basic-form' onSubmit={this.handleSubmit}>
          <h3>Charity Details</h3>
          <label htmlFor='organisation'>Charity Name</label>
          <input
            type='text'
            name='organisation'
            id='organisation'
            value={this.state.charity.organisation}
            onChange={this.handleChange}
          />

          <label htmlFor='addressLine1'>Address Line 1</label>
          <input
            type='text'
            name='addressLine1'
            id='addressLine1'
            value={this.state.charity.addressLine1}
            onChange={this.handleChange}
          />

          <label htmlFor='addressLine2'>Address Line 2</label>
          <input
            type='text'
            name='addressLine2'
            id='addressLine2'
            value={this.state.charity.addressLine2}
            onChange={this.handleChange}
          />

          <label htmlFor='location'>Location (Displayed on Charity List)</label>
          <input
            type='text'
            name='location'
            id='location'
            value={this.state.charity.location}
            onChange={this.handleChange}
          />

          <label htmlFor='postCode'>Postcode</label>
          <input
            type='text'
            name='postCode'
            id='postCode'
            value={this.state.charity.postCode}
            onChange={this.handleChange}
          />

          <h3>Contact Details</h3>
          <label htmlFor='contactName'>Contact Name</label>
          <input
            type='text'
            name='contactName'
            id='contactName'
            value={this.state.charity.contactName}
            onChange={this.handleChange}
            placeholder='Jane Doe'
          />

          <label htmlFor='contactEmail'>Contact Email</label>
          <input
            type='text'
            name='contactEmail'
            id='contactEmail'
            value={this.state.charity.contactEmail}
            onChange={this.handleChange}
            placeholder='jane@gmail.com'
          />

          <label htmlFor='contactPhone'>Contact Phone Number</label>
          <input
            type='text'
            name='contactPhone'
            id='contactPhone'
            value={this.state.charity.contactPhone}
            onChange={this.handleChange}
            placeholder='07123456789'
          />

          <h3>Opening Hours</h3>
          <label htmlFor='monday'>Monday:</label>
          <input
            type='text'
            name='monday'
            id='monday'
            value={this.state.charity.openingDetails.monday}
            onChange={this.handleChangeOpening}
            placeholder='9-5'
          />

          <label htmlFor='tuesday'>Tuesday:</label>
          <input
            type='text'
            name='tuesday'
            id='tuesday'
            value={this.state.charity.openingDetails.tuesday}
            onChange={this.handleChangeOpening}
            placeholder='9-5'
          />

          <label htmlFor='wednesday'>Wednesday:</label>
          <input
            type='text'
            name='wednesday'
            id='wednesday'
            value={this.state.charity.openingDetails.wednesday}
            onChange={this.handleChangeOpening}
            placeholder='closed'
          />

          <label htmlFor='thursday'>Thursday:</label>
          <input
            type='text'
            name='thursday'
            id='thursday'
            value={this.state.charity.openingDetails.thursday}
            onChange={this.handleChangeOpening}
            placeholder='10-6.30'
          />

          <label htmlFor='friday'>Friday:</label>
          <input
            type='text'
            name='friday'
            id='friday'
            value={this.state.charity.openingDetails.friday}
            onChange={this.handleChangeOpening}
            placeholder='10-5'
          />

          <label htmlFor='saturday'>Saturday:</label>
          <input
            type='text'
            name='saturday'
            id='saturday'
            value={this.state.charity.openingDetails.saturday}
            onChange={this.handleChangeOpening}
            placeholder='12-4'
          />

          <label htmlFor='sunday'>Sunday:</label>
          <input
            type='text'
            name='sunday'
            id='sunday'
            value={this.state.charity.openingDetails.sunday}
            onChange={this.handleChangeOpening}
            placeholder='closed'
          />

          <label htmlFor='dropoffWindow'>Dropoff Window</label>
          <input
            type='text'
            name='dropoffWindow'
            id='dropoffWindow'
            value={this.state.charity.dropoffWindow}
            onChange={this.handleChange}
          />

          <h3>Gifts Required</h3>
          {giftsRequiredFormFields}

          <h3>Public Facing Contact Details</h3>
          <label htmlFor='publicContactEmail'>Email Address:</label>
          <input
            type='text'
            name='publicContactEmail'
            id='publicContactEmail'
            value={this.state.charity.publicContactEmail}
            onChange={this.handleChange}
            placeholder='studentemailaddress@gsoc.com'
          />

          <p>{this.state.errorMessage}</p>
          <button className='button-red-small' type='submit'>
            Save Changes
          </button>
          <br />
        </form>
      </div>
    );
  }
}

export default EditCharity;
