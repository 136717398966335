import React from 'react';
import './Button.scss'

const Button = ({ variant = "outlined", disabled, children, onClick }) => {
  const className = `button button-${variant}`;

  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;