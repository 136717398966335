import React, { useEffect, useState } from 'react';
import { db, fv } from '../../Firebase/firebase';

const ManageFlagged = (props) => {
  const [pledges, setPledges] = useState(props.pledges);
  const [flagged, setFlagged] = useState([]);
  const [charities, setCharities] = useState({});
  const [abandoned, setAbandoned] = useState([]);

  useEffect(() => {
    const flagged = pledges.filter((pledge) => {
      return pledge.flagged;
    });
    const abandoned = pledges.filter((pledge) => {
      return pledge.pledgeAbandoned;
    });
    const mapped = {};
    props.charities.forEach((charity) => {
      mapped[charity.id] = charity;
    });
    setFlagged(flagged);
    setCharities(mapped);
    setAbandoned(abandoned);
  }, [pledges, props.charities]);

  const pledgeDump = (pledge) => {
    const gifts = Object.keys(pledge.donation).map((key) => {
      return key + '(' + pledge.donation[key] + ')';
    });
    return gifts.join(', ');
  };

  const updatePledge = (updatedPledge) => {
    const newPledges = pledges.map((pledge) => {
      if (pledge.id === updatedPledge.id) {
        return updatedPledge;
      } else {
        return pledge;
      }
    });
    setPledges(newPledges);
  };

  const pledgeRemoved = (pledge) => {
    const newPledges = pledges.filter((e) => {
      return pledge.id !== e.id;
    });
    setPledges(newPledges);
  };

  const markAbandoned = (pledge) => {
    pledge.flagged = false;
    pledge.pledgeAbandoned = true;
    db.collection('pledges').doc(pledge.id).update(
      {
        flagged: false,
        pledgeAbandoned: true,
      },
      { merge: true }
    );
    updatePledge(pledge);
  };

  const markUnflagged = (pledge) => {
    if (!window.confirm('Are you sure you wish to unflag this pledge?')) {
      return;
    }

    pledge.flagged = false;
    pledge.pledgeAbandoned = false;
    pledge.comments = [];
    db.collection('pledges').doc(pledge.id).update(
      {
        flagged: false,
        pledgeAbandoned: false,
        comments: [],
      },
      { merge: true }
    );
    updatePledge(pledge);
  };

  const cancelPledge = (pledge) => {
    if (
      !window.confirm(
        'Are you sure you wish to cancel this, pledge?\n\n(This operation cannot be undone)'
      )
    ) {
      return;
    }

    var charityUpdates = { giftsRequired: {} };
    Object.keys(pledge.donation).forEach((key) => {
      charityUpdates['giftsRequired'][key] = fv.increment(
        parseInt(pledge.donation[key])
      );
    });
    db.collection('charities')
      .doc(pledge.charityId)
      .set(charityUpdates, { merge: true });

    db.collection('pledges').doc(pledge.id).delete();

    pledgeRemoved(pledge);
  };

  const makeFlagged = (pledge) => {
    pledge.flagged = true;
    pledge.pledgeAbandoned = false;
    db.collection('pledges').doc(pledge.id).update(
      {
        flagged: true,
        pledgeAbandoned: false,
      },
      { merge: true }
    );
    updatePledge(pledge);
  };

  function buildTable(items, buttons) {
    return (
      <div className='dashboard-container'>
        <table className='dashboard-table'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Donor</th>
              <th>Charity</th>
              <th>Pledge</th>
              <th>Comment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((pledge) => {
              const charity = charities[pledge.charityId];
              return (
                <tr key={pledge.id}>
                  <td>{pledge.id}</td>
                  <td>{pledge.donator.name}</td>
                  <td>{charity ? charity.organisation : '??'}</td>
                  <td>{pledgeDump(pledge)}</td>
                  <td>{pledge.comments ? pledge.comments.join(', ') : null}</td>
                  <td>
                    {buttons.map((button) => {
                      return (
                        <button
                          className={button.className}
                          key={button.label}
                          onClick={() => button.action(pledge)}
                        >
                          {button.label}
                        </button>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  var flaggedItemsTable = <span>No flagged items</span>;
  if (flagged.length > 0) {
    flaggedItemsTable = buildTable(flagged, [
      {
        label: 'Mark Abandoned',
        action: markAbandoned,
        className: 'button-red-small',
      },
      {
        label: 'Unflag',
        action: markUnflagged,
        className: 'button-grey-small',
      },
    ]);
  }

  var abandondedItemsTable = <span>No abandoned items</span>;
  if (abandoned.length > 0) {
    abandondedItemsTable = buildTable(abandoned, [
      {
        label: 'Unabandon',
        action: makeFlagged,
        className: 'button-red-small',
      },
      {
        label: 'Cancel Pledge',
        action: cancelPledge,
        className: 'button-grey-small',
      },
    ]);
  }

  return (
    <>
      <p>
        This page shows pledges with comments which may need to be marked
        abandoned, and any abandoned items which need to be cancelled.
      </p>

      <h2>Flagged Items</h2>

      {flaggedItemsTable}

      <h2>Abandoned Items</h2>

      {abandondedItemsTable}
    </>
  );
};

export default ManageFlagged;
