import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebaseConfig.js";
import { ToastContainer, toast } from "react-toastify";
import { serverTimestamp } from "firebase/firestore";

import BackdropModal from "../../Components/BackdropModal/BackdropModal.js";
import Form from "../../Components/Form/Form.js";
import Button from "../../Components/Button/Button.js";
import SaveModal from "./SaveModal.js";

import './PartnerDashboard.scss';
import "react-toastify/dist/ReactToastify.css";

function PartnerProfile({ selectedCharity }) {
  let initialState = {
    charity: {
      organisation: "",
      addressLine1: "",
      addressLine2: "",
      location: "",
      postCode: "",
      openingDetails: {
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
      },
      dropoffWindow: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      publicContactEmail: "",
    },
    errorMessage: null,
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const savedNotification = () =>
    toast("Saved successfully!", {
      type: "success",
    });

  useEffect(() => {
    let charityData;

    const fetchData = async () => {
      await db
        .collection("charities")
        .where("userId", "==", selectedCharity)
        .onSnapshot((res) => {
          const dbUser = res.docs[0];
          charityData = dbUser.data();

          setFormData({ charity: charityData });
          setLoading(false);
        });
    };

    fetchData();
  }, [selectedCharity]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => {
      let newState = { ...prevData };
      newState.charity[name] = value;

      return newState;
    });
  };

  const handleChangeOpening = (event) => {
    let propertyName = event.target.name;
    let charity = formData.charity;
    charity.openingDetails[propertyName] = event.target.value;
    setFormData({ charity: charity });
  };

  const handleUpdateCharity = (charity) => {
    db.collection("charities").doc(charity.id).set(charity);
  };

  const handleInternalSubmit = (event) => {
    event.preventDefault();
    let charity = formData.charity;

    if (
      !charity.organisation ||
      !charity.addressLine1 ||
      !charity.addressLine2 ||
      !charity.location ||
      !charity.postCode ||
      !charity.dropoffWindow ||
      !charity.contactName ||
      !charity.contactEmail ||
      !charity.contactPhone
    ) {
      setFormData((prevData) => {
        let newState = { ...prevData };
        newState.errorMessage =
          "Please complete all fields before attempting to save the charity details.";

        return newState;
      });
    } else {
      setFormData((prevData) => {
        let newState = { ...prevData };
        delete newState.errorMessage;
        return newState;
      });

      let charityDetails = {
        ...charity,
        profileLastModified: serverTimestamp(),
      };

      handleUpdateCharity(charityDetails);
      savedNotification();
    }
    setShowModal(false);
  };

  const handleSaveChangesButton = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleCancelSubmit = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const registerModalContent = (
    <>
      <h2>Alert</h2>

      <div className="modal-body">
        <p>You are about to save your details.</p>
        <p>Would you like to proceed?</p>
      </div>
    </>
  );

  if (loading) {
    return <div>Loading...</div>;
  } else {
    const lastModifiedDate =
      formData.charity?.profileLastModified &&
      new Date(formData.charity?.profileLastModified?.seconds * 1000);
    const lastModifiedDateTime =
      lastModifiedDate &&
      `${lastModifiedDate.toLocaleDateString()} ${lastModifiedDate.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      )}`;

    return (
      <div className="partner-dashboard">
        {lastModifiedDate && (
          <div className="public-contact">
            <strong>Last modified:</strong> {lastModifiedDateTime}
          </div>
        )}
        
        <ToastContainer />

        <BackdropModal>
          <Form onSubmit={handleSaveChangesButton}>
            <h1>Charity Details</h1>
            <label htmlFor="organisation">Organisation Name</label>
            <input
              type="text"
              name="organisation"
              id="organisation"
              value={formData.charity.organisation}
              onChange={handleChange}
              required
              disabled
            />

            <label htmlFor="addressLine1">Address Line 1</label>
            <input
              type="text"
              name="addressLine1"
              id="addressLine1"
              value={formData.charity.addressLine1}
              onChange={handleChange}
              required
              disabled
            />

            <label htmlFor="addressLine2">Address Line 2</label>
            <input
              type="text"
              name="addressLine2"
              id="addressLine2"
              value={formData.charity.addressLine2}
              onChange={handleChange}
              required
              disabled
            />

            <label htmlFor="location">Location/Area</label>
            <input
              type="text"
              name="location"
              id="location"
              value={formData.charity.location}
              onChange={handleChange}
              required
              disabled
            />

            <label htmlFor="postCode">Postcode</label>
            <input
              type="text"
              name="postCode"
              id="postCode"
              value={formData.charity.postCode}
              onChange={handleChange}
              required
              disabled
            />

            <h3>Contact Details</h3>
            <label htmlFor="contactName">Contact Name</label>
            <input
              type="text"
              name="contactName"
              id="contactName"
              value={formData.charity.contactName}
              onChange={handleChange}
              placeholder="Jane Doe"
              required
            />

            <label htmlFor="contactEmail">Contact Email</label>
            <input
              type="email"
              name="contactEmail"
              id="contactEmail"
              value={formData.charity.contactEmail}
              onChange={handleChange}
              placeholder="jane@gmail.com"
              required
              disabled
            />

            <label htmlFor="contactPhone">Contact Phone Number</label>
            <input
              type="text"
              name="contactPhone"
              id="contactPhone"
              value={formData.charity.contactPhone}
              onChange={handleChange}
              placeholder="07123456789"
              required
            />

            <h3>Opening Hours</h3>
            <label htmlFor="monday">Monday:</label>
            <input
              type="text"
              name="monday"
              id="monday"
              value={formData.charity.openingDetails.monday}
              onChange={handleChangeOpening}
              placeholder="9-5"
              required
            />

            <label htmlFor="tuesday">Tuesday:</label>
            <input
              type="text"
              name="tuesday"
              id="tuesday"
              value={formData.charity.openingDetails.tuesday}
              onChange={handleChangeOpening}
              placeholder="9-5"
              required
            />

            <label htmlFor="wednesday">Wednesday:</label>
            <input
              type="text"
              name="wednesday"
              id="wednesday"
              value={formData.charity.openingDetails.wednesday}
              onChange={handleChangeOpening}
              placeholder="closed"
              required
            />

            <label htmlFor="thursday">Thursday:</label>
            <input
              type="text"
              name="thursday"
              id="thursday"
              value={formData.charity.openingDetails.thursday}
              onChange={handleChangeOpening}
              placeholder="10-6.30"
              required
            />

            <label htmlFor="friday">Friday:</label>
            <input
              type="text"
              name="friday"
              id="friday"
              value={formData.charity.openingDetails.friday}
              onChange={handleChangeOpening}
              placeholder="10-5"
              required
            />

            <label htmlFor="saturday">Saturday:</label>
            <input
              type="text"
              name="saturday"
              id="saturday"
              value={formData.charity.openingDetails.saturday}
              onChange={handleChangeOpening}
              placeholder="12-4"
              required
            />

            <label htmlFor="sunday">Sunday:</label>
            <input
              type="text"
              name="sunday"
              id="sunday"
              value={formData.charity.openingDetails.sunday}
              onChange={handleChangeOpening}
              placeholder="closed"
              required
            />

            <label htmlFor="dropoffWindow">Dropoff Window</label>
            <input
              type="text"
              name="dropoffWindow"
              id="dropoffWindow"
              value={formData.charity.dropoffWindow}
              onChange={handleChange}
              required
            />

            <p className="red-text">{formData.errorMessage}</p>
            <Button type="submit">Save Changes</Button>
            <br />
          </Form>

          <SaveModal
            isOpen={showModal}
            onConfirm={handleInternalSubmit}
            onCancel={handleCancelSubmit}
          >
            {registerModalContent}
          </SaveModal>
        </BackdropModal>
      </div>
    );
  }
}

export default PartnerProfile;
