import React from 'react';

const AboutText = () => (

  <div className="about-text">
    <h1>Resources</h1>
    <p className="charity-text">Discover how to setup a <strong>Company or Office Secret Santa</strong> or download our poster to share by clicking on the images below;</p>

    <section>
      <h2><strong>Glasgow’s Spirit of Christmas Logo</strong></h2>
      <a href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Logo.png" alt="GSOC 2023 Logo" />
      </a>
      <br />
      <h2><strong>Glasgow’s Spirit of Christmas Poster</strong></h2>
      <a href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Poster.png" alt="GSOC 2023 Poster" />
      </a>
      <br />
      <br />
      <h2><strong>Glasgow’s Spirit of Christmas Be A Secret Santa Guide 2023</strong></h2>
      <a href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Be_A_Secret_Santa_P1.png" alt="GSOC 2023 Be a Secret Santa Guide Part 1" />
      </a>
      <a href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Be_A_Secret_Santa_P2.png" alt="GSOC 2023 Be a Secret Santa Guide Part 2" />
      </a>
      <br />
      <h2><strong>Glasgow’s Spirit of Christmas Office Secret Santa Guide 2023</strong></h2>
      <a href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Secret_Santa_Office_P1.png" alt="GSOC 2023 Office Secret Santa Guide Part 1" />
      </a>
      <a href="https://www.cultivatingmindfulness.org.uk/fundraising-materials" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Secret_Santa_Office_P2.png" alt="GSOC 2023 Office Secret Santa Guide Part 2" />
      </a>
      <br />
      <br />
      <h2><strong>Fundraising Events</strong></h2>
      <h3>Join our first ever <a href="https://www.cultivatingmindfulness.org.uk/shop" aria-label="Christmas Ball Tickets at cultivatingmindfulness.org.uk">Christmas Ball</a> this 3rd November 2023 to celebrate our 10th Anniversary gift campaign.</h3>
      <a href="https://www.cultivatingmindfulness.org.uk/shop" aria-label="Download our graphic resources at cultivatingmindfulness.org.uk">
        <img className="resources-image" src="/images/GSOC_2023_Ball_Ticket.png" alt="GSOC 2023 Christmas Ball Ticket" />
      </a>

      <h3>Join our Facebook Community Group</h3>
      <p className="charity-text">Be part of the #theplacewherekindnesshappens, access resources, share your experience and spread some love and kindness on the run up to Christmas <a className="inline-link" href="https://www.facebook.com/groups/613947025394004/" aria-label="Check our Facebook group page">here.</a></p>
    </section>
  </div>

);


export default AboutText;
