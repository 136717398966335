import React from 'react';

class DonationSummaryEditable extends React.Component {
  constructor(props) {
    super(props);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleChangeCharity = this.handleChangeCharity.bind(this);
  }

  handleChangeCharity() {
    this.props.updateNewDonation({});
    this.props.updateCharity(null);
    this.props.updateView();
  }

  handleContinue() {
    this.props.donationCreated();
  }

  render() {
    return (
      <React.Fragment>
        <div className='donation-summary-editable'>
          <h3 className='donation-summary-heading'>Your Donation Summary</h3>
          <h4 className='donation-summary-sub-heading'>Charity:</h4>
          <p>{this.props.charity.organisation}</p>
          <p>
            {this.props.charity.addressLine1}, {this.props.charity.addressLine2}
            , {this.props.charity.postCode}
          </p>
          <button
            className='button-grey-small'
            onClick={this.handleChangeCharity}
          >
            Change Charity
          </button>
        </div>
        <p>{this.props.errorMessage}</p>
        <button className='button-red-medium' onClick={this.handleContinue}>
          Continue
        </button>
      </React.Fragment>
    );
  }
}

export default DonationSummaryEditable;
