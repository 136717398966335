import React, { useState } from "react";
import Select from "../../shared/Select";

import "./styles/ManagePortfolio.css";

export const participatingOptions = [
    "Participating",
    "Pending",
    "Not Participating",
]

const CharityParticipationSelect = ({charity, updateStatus}) => {

    const [ status, setSelectedStatus ] = useState(charity?.participationStatus?.status)

    const handleSelectedStatus = (status) => {
        setSelectedStatus(status)
        updateStatus(charity.id, status)
    }

    return (
        <li className={`participation-select ${status}`}>
            <label className="manage-portfolio-grid">
                {charity.organisation}
                <Select options={participatingOptions} setSelectedOption={handleSelectedStatus} selectedOption={status} />
            </label>
        </li>
    )
}

const ManagePortfolios = ({charities = [], updateStatus}) => {
    const lastModifiedCharity = charities.reduce((a, b) => {
        const aModified = a?.participationStatus?.lastModified ?? 0;
        const bModified = b?.participationStatus?.lastModified ?? 0;
        return aModified >= bModified ? a : b
    }, false);
    const lastModifiedDate = lastModifiedCharity?.participationStatus?.lastModified && new Date(lastModifiedCharity?.participationStatus?.lastModified?.seconds * 1000);
    const lastModifiedDateTime = lastModifiedDate && `${lastModifiedDate.toLocaleDateString()} ${lastModifiedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    
    const sortedCharities = charities.sort((a, b) => a.organisation.localeCompare(b.organisation));
    
    return (
        <section>
            <p className="last-modified-date">{lastModifiedDateTime ? `Last modified on ${lastModifiedDateTime}` : ""}</p>
            <h1>Manage Portfolio</h1>
            {sortedCharities.length && (
                <>  
                    <div className="manage-portfolio-grid">
                        <p className="manage-portfolio-status">Current Status</p>
                    </div>
                    <ul className="portfolio-container">
                        {charities.map(charity => (
                            <CharityParticipationSelect key={charity.id} charity={charity} updateStatus={updateStatus} />
                        ))}
                    </ul>
                </>
            )}

        </section>
    );
};

export default ManagePortfolios