import React from 'react';
import { Link } from 'react-router-dom';
import FAQSteps from '../../FAQContainer/components/FAQSteps';

const Home = function () {
  return (
    <React.Fragment>
      <div>
        <h1>Glasgow's Spirit of Christmas</h1>
        <p className="charity-text">Imagine waking up as a child on Christmas Day to no toys, gifts or a hot meal. Every child deserves gifts on Christmas Day no matter what their circumstances are.</p>
        <p className="charity-text">Now imagine that your generosity has turned that sad face into a bright happy excited child on Christmas morning. Giving is the true spirit of Christmas!</p>
        <p className="charity-text">Can you donate just one gift made with love? </p>
        <img className="GSoC-image" src="/images/GSOC_2023_Logo.png" alt="GSOC 2023 Logo" />

        <p className="charity-text">Glasgow's Spirit of Christmas was founded in 2014 with just one <strong>‘Santa Stop Here’</strong> sign in a carpark that inspired people from all over to donate gifts and become a <strong>Secret Santa</strong> for a local child less fortunate. </p>
        <p className="charity-text">This year will be our 10th annual gift campaign of supporting local children affected by hardship or living in poverty in Glasgow and surrounding regions, including North and South Lanarkshire, East and West Dunbartonshire, Dumbarton District, Renfrewshire, Inverclyde, North, South and East Ayrshire, Argyll & Bute and East Renfrewshire.</p>
        <img className="GSoC-image" src="/images/GSOC_2023_Badge.png" alt="GSOC 2023 Badge" />

        <p className="charity-text">The sad reality is that 1 in 5 children in Scotland and 1 in 3 in Glasgow lives in poverty and many more are affected by hardship this year due to continued economic uncertainty and the cost of living crisis. That figure is set to rise over the coming years with a massive increase forecasted in child poverty.</p>
        <p className="charity-text">We want to protect and make sure every child is included at Christmas and has a wonderful magical experience. You and your family can become a <strong>Secret Santa</strong> to a local child in need this festive season. No-one can imagine waking up as a child on Christmas Day to no presents.</p>
        
        <p className="charity-text">Since that first Christmas in 2014 our kind community of members, donors and supporters have created the magic of Christmas for <strong>102,700 local children</strong>. We partner with over 100 registered Charities, the Health and Social Care Partnership, County Councils, local community projects and groups and have donated over <strong>£3.8 million worth of gifts</strong> going to children living in and around Glasgow and the surrounding regions.</p>
        <img className="GSoC-image-home" src="/images/GSOC_2023_Figures.png" alt="GSOC 2023 10th Year Figures" />

        <p className="charity-text">To read more about our charity and the campaign please visit the website at</p>
        <p className="charity-text"><a href="https://www.cultivatingmindfulness.org.uk/glasgows-spirit-of-christmas" aria-label="Read more at cultivatingmindfulness.org.uk">https://www.cultivatingmindfulness.org.uk/glasgows-spirit-of-christmas</a></p>


        <h1 className="red-title">How To Donate</h1>
        <FAQSteps />
        <br />
        <h2 className="magic-title" >BRING THE MAGIC OF CHRISTMAS TO A LOCAL CHILD IN NEED THIS YEAR.</h2>
        <img className="magic-title-poster" src="/images/SantaHeart.jpg" alt="Santa Holding Heart" />
      </div>

      <div className="pledge-button-container">
        <br />
        <Link to="/pledge" className="donate-button">Pledge a Donation</Link>
        <br />
        <br />
      </div>
    </React.Fragment>
  )
}

export default Home;
