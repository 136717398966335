import React from 'react';
import QRCode from 'qrcode.react';

class GiftLabel extends React.Component {

  componentDidMount() {
    const canvas = document.querySelector('#qrcode');
    this.props.codeGenerated(canvas)
  }

  render() {
    let props = this.props
    const shortcode = props.code.toUpperCase().slice(0,6);
    const acceptURL = "https://glasgowsspiritofchristmas.org.uk/admin/qrcodescan/" + props.code;

    return (
      <div className="gift-label">
        <div className="ref">REF: Glasgow Spirit of Christmas</div>
        <div className="ref">{props.code}</div>
        <h1>DONATION CODE: {shortcode} </h1>
        <div className="qrcode">
          <QRCode id="qrcode" value={acceptURL} size={192} includeMargin={true} />
        </div>
        <div className="charity">
          <h2>{props.charity.organisation}</h2>
          <h3 className="donation-summary-sub-heading">Address:</h3>
          <p>{props.charity.addressLine1}</p>
          <p>{props.charity.addressLine2}</p>
          <p>{props.charity.postCode}</p>
          <p><strong>Drop Off Window:</strong> {props.charity.dropoffWindow}</p>
        </div>
      </div>
    )

  }

}

export default GiftLabel;