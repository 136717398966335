import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../Firebase/firebase";

// Create a context to hold the user details
const AuthContext = createContext();

// Custom hook to acccess the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Custom hook to handle authentication and user details
export const useAuthProvider = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        let userDetails;

        const fetchData = async () => {
          await db
            .collection("users")
            .where("userId", "==", firebaseUser.uid)
            .onSnapshot((res) => {
              const dbUser = res.docs[0];

              // If the user is logged in, fetch the user's (role) from Firebase
              userDetails = {
                email: firebaseUser.email,
                authID: firebaseUser.uid,
                accessLevel: dbUser.data().accessLevel,
              };
              
              setUser({
                uid: firebaseUser.uid,
                email: firebaseUser.email,
                role: userDetails.accessLevel,
              });
            });
        };

        fetchData();
      } else {
        setUser(null);
      }

      setLoading(false);
    });

    // Cleanup function
    return () => unsubscribe();
  }, []);

  return { user, loading };
};

// AuthProvider component to wrap the app
export const AuthProvider = ({ children }) => {
  const user = useAuthProvider();

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
