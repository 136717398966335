import React from 'react';
import FAQ from '../components/FAQ.js';

const FAQContainer = function(){
  return (
    <React.Fragment>
      <h1>Frequently Asked Questions</h1>
      <FAQ />
    </React.Fragment>
  )
}

export default FAQContainer;
